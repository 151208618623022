import { apiSlice } from './apiSlice';
const DATA_URL = '/v1/elections';

export const electionsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getTableData: builder.query({
            query: (table) => ({
                url: `${DATA_URL}/${table}`, // ${document.location.pathname.split("/")[2]}
                method: 'GET',
                credentials: 'include',
            }),
        }),
    }),
});

export const { useGetTableDataQuery } = electionsApiSlice;
