import { apiSlice } from './apiSlice';
const DATA_URL = '/v1/moderation';

export const rolesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        // login: builder.mutation({
        //     query: (data) => ({
        //         url: `${USERS_URL}/auth/login`,
        //         method: "POST",
        //         body: data,
        //         credentials: "include",
        //     }),
        // }),
        // getTableData: builder.query({
        //     query: (table) => ({
        //         url: `${DATA_URL}/${table}`, // ${document.location.pathname.split("/")[2]}
        //         method: 'GET',
        //         credentials: 'include',
        //     }),
        // }),
        // getActiveBanData: builder.query({
        //     query: ({page}) => ({
        //         url: page != null ? `${DATA_URL}/bans/${page}?reverse&active` : `${DATA_URL}/bans?reverse&active`,
        //         method: "GET",
        //         credentials: "include",
        //     })
        // }),
        // getInactiveBanData: builder.query({
        //     query: ({page}) => ({
        //         url: page != null ? `${DATA_URL}/bans/${page}?reverse` : `${DATA_URL}/bans?reverse`,
        //         method: "GET",
        //         credentials: "include",
        //     })
        // }),
        getBanData: builder.query({
            query: ({ page, active }) => ({
                url:
                    page != null
                        ? `${DATA_URL}/bans/${page}?reverse${active ? '&active' : ''}`
                        : `${DATA_URL}/bans?reverse${active ? '&active' : ''}`, // ${document.location.pathname.split("/")[2]}
                method: 'GET',
                credentials: 'include',
            }),
        }),
        getStaffData: builder.query({
            query: () => ({
                url: `${DATA_URL}/staff`, // ${document.location.pathname.split("/")[2]}
                method: 'GET',
                credentials: 'include',
            }),
        }),
        getThumbnails: builder.query({
            query: (ids) => {
                if (ids.length === 0) {
                    // throw new Error("No ids provided");
                    return {
                        url: '',
                        method: 'GET',
                        credentials: 'include',
                        skip: true,
                    };
                }
                return {
                    url: '/v1/roblox/thumbnails?ids=' + ids.join(','),
                    method: 'GET',
                    credentials: 'include',
                };
            },
        }),
        deleteBan: builder.mutation({
            query: (ids) => ({
                url: `${DATA_URL}/bans`,
                body: ids,
                method: 'DELETE',
                credentials: 'include',
            }),
        }),
        createBan: builder.mutation({
            query: (data) => ({
                url: `${DATA_URL}/bans`,
                body: data,
                method: 'POST',
                credentials: 'include',
            }),
        }),
        updateBan: builder.mutation({
            query: (data) => ({
                url: `${DATA_URL}/bans`,
                body: data,
                method: 'PATCH',
                credentials: 'include',
            }),
        }),
    }),
});

export const {
    useGetBanDataQuery,
    useGetStaffDataQuery,
    useGetThumbnailsQuery,
    useDeleteBanMutation,
    useCreateBanMutation,
    useUpdateBanMutation,
} = rolesApiSlice;
