import { useState } from 'react';
import {
    Box,
    Button,
    Typography,
    useTheme,
    Grid,
    Paper,
    Divider,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
} from '@mui/material';
import { getColors } from '@src/global/context/theme';

import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import SouthEastOutlinedIcon from '@mui/icons-material/SouthEastOutlined';

import Header from '@src/global/components/Header';
import StatBox from '@src/global/components/StatBox';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

const PrivacyPolicy = (): JSX.Element => {
    const theme = useTheme();
    const colors = getColors(theme.palette.mode);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ p: 2 }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        ml: '10vw',
                        mr: '10vw',
                    }}
                >
                    <Header
                        title="Privacy Notice"
                        subtitle="Last updated January 5th, 2024"
                    />

                    <Paper variant="outlined" sx={{ p: 2 }}>
                        <Typography variant="h4" fontWeight={'bold'}>
                            This privacy notice for USA Cyber Services
                            (&apos;Company&apos;, &apos;we&apos;,
                            &apos;us&apos;, or &apos;our&apos;,), describes how
                            and why we might collect, store, use, and/or share
                            (&apos;process&apos;) your information when you use
                            our services (&apos;Services&apos;), such as when
                            you:
                        </Typography>
                        <List sx={{ listStyleType: 'disc', pl: 4 }}>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="Visit our website at https://robloxnusa.com, or any website of ours that links to this privacy notice" />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="Engage with us in other related ways, including any sales, marketing, or events" />
                            </ListItem>
                        </List>
                        <Typography variant="h4" fontWeight={'bold'}>
                            Questions or concerns? Reading this privacy notice
                            will help you understand your privacy rights and
                            choices. If you do not agree with our policies and
                            practices, please do not use our Services. If you
                            still have any questions or concerns, please contact
                            us by using the contact details provided in the
                            section &apos;HOW CAN YOU CONTACT US ABOUT THIS
                            NOTICE?&apos; below.
                        </Typography>
                    </Paper>
                    <br />
                    <Paper variant="outlined" sx={{ p: 2 }}>
                        <Typography variant="h2" fontWeight={'bold'}>
                            SUMMARY OF KEY POINTS
                        </Typography>
                        <Typography variant="h4" sx={{ marginTop: '0.5rem' }}>
                            This summary provides key points from our privacy
                            notice, but you can find out more details about any
                            of these topics by clicking the link following each
                            key point or by using our table of contents below to
                            find the section you are looking for.
                        </Typography>
                        <Divider
                            sx={{ marginTop: '0.5rem', marginBottom: '1rem' }}
                            light
                        />

                        <List sx={{ listStyleType: 'disc', pl: 4 }}>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText
                                    primary="What personal information do we process? When you visit, use, or navigate our Services, we may process personal information depending on how you interact
                 with USA Cyber Services and the Services, the choices you make, and the products and features you use."
                                />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="Do we process any sensitive personal information? We do not process sensitive personal information." />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="Do we receive any information from third parties? We do not receive any information from third parties other than Discord or Roblox." />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="How do we process your information? We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so." />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="In what situations and with which parties do we share personal information? We may share information in specific situations and with specific third parties." />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="How do we keep your information safe? We have organisational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information." />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="What are your rights? Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information." />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="How do you exercise your rights? The easiest way to exercise your rights is by contacting us. We will consider and act upon any request in accordance with applicable data protection laws." />
                            </ListItem>
                        </List>
                    </Paper>
                    <br />
                    <Paper variant="outlined" sx={{ p: 2 }}>
                        <Typography variant="h2" fontWeight={'bold'}>
                            TABLE OF CONTENTS
                        </Typography>
                        <Divider sx={{ marginBottom: '1rem' }} light />

                        <List sx={{ listStyleType: 'decimal', pl: 4 }}>
                            <ListItemButton
                                sx={{ display: 'list-item' }}
                                onClick={() => {
                                    document
                                        .getElementsByClassName(
                                            'what-information-do-we-collect',
                                        )[0]
                                        ?.scrollIntoView({
                                            behavior: 'smooth',
                                            inline: 'nearest',
                                        });
                                }}
                            >
                                <ListItemText primary="WHAT INFORMATION DO WE COLLECT?" />
                            </ListItemButton>
                            <ListItemButton
                                sx={{ display: 'list-item' }}
                                onClick={() => {
                                    document
                                        .getElementsByClassName(
                                            'how-do-we-process-your-information',
                                        )[0]
                                        ?.scrollIntoView({
                                            behavior: 'smooth',
                                            inline: 'nearest',
                                        });
                                }}
                            >
                                <ListItemText primary="HOW DO WE PROCESS YOUR INFORMATION?" />
                            </ListItemButton>
                            <ListItemButton
                                sx={{ display: 'list-item' }}
                                onClick={() => {
                                    document
                                        .getElementsByClassName(
                                            'what-legal-bases-do-we-rely-on-to-process-your-information',
                                        )[0]
                                        ?.scrollIntoView({
                                            behavior: 'smooth',
                                            inline: 'nearest',
                                        });
                                }}
                            >
                                <ListItemText primary="WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?" />
                            </ListItemButton>
                            <ListItemButton
                                sx={{ display: 'list-item' }}
                                onClick={() => {
                                    document
                                        .getElementsByClassName(
                                            'when-and-with-whom-do-we-share-your-personal-information',
                                        )[0]
                                        ?.scrollIntoView({
                                            behavior: 'smooth',
                                            inline: 'nearest',
                                        });
                                }}
                            >
                                <ListItemText primary="WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?" />
                            </ListItemButton>
                            <ListItemButton
                                sx={{ display: 'list-item' }}
                                onClick={() => {
                                    document
                                        .getElementsByClassName(
                                            'do-we-use-cookies-and-other-tracking-technologies',
                                        )[0]
                                        ?.scrollIntoView({
                                            behavior: 'smooth',
                                            inline: 'nearest',
                                        });
                                }}
                            >
                                <ListItemText primary="DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?" />
                            </ListItemButton>
                            <ListItemButton
                                sx={{ display: 'list-item' }}
                                onClick={() => {
                                    document
                                        .getElementsByClassName(
                                            'how-do-we-handle-your-social-logins',
                                        )[0]
                                        ?.scrollIntoView({
                                            behavior: 'smooth',
                                            inline: 'nearest',
                                        });
                                }}
                            >
                                <ListItemText primary="HOW DO WE HANDLE YOUR SOCIAL LOGINS?" />
                            </ListItemButton>
                            <ListItemButton
                                sx={{ display: 'list-item' }}
                                onClick={() => {
                                    document
                                        .getElementsByClassName(
                                            'how-long-do-we-keep-your-information',
                                        )[0]
                                        ?.scrollIntoView({
                                            behavior: 'smooth',
                                            inline: 'nearest',
                                        });
                                }}
                            >
                                <ListItemText primary="HOW LONG DO WE KEEP YOUR INFORMATION?" />
                            </ListItemButton>
                            <ListItemButton
                                sx={{ display: 'list-item' }}
                                onClick={() => {
                                    document
                                        .getElementsByClassName(
                                            'how-do-we-keep-your-information-safe',
                                        )[0]
                                        ?.scrollIntoView({
                                            behavior: 'smooth',
                                            inline: 'nearest',
                                        });
                                }}
                            >
                                <ListItemText primary="HOW DO WE KEEP YOUR INFORMATION SAFE?" />
                            </ListItemButton>
                            <ListItemButton
                                sx={{ display: 'list-item' }}
                                onClick={() => {
                                    document
                                        .getElementsByClassName(
                                            'what-are-your-privacy-rights',
                                        )[0]
                                        ?.scrollIntoView({
                                            behavior: 'smooth',
                                            inline: 'nearest',
                                        });
                                }}
                            >
                                <ListItemText primary="WHAT ARE YOUR PRIVACY RIGHTS?" />
                            </ListItemButton>
                            <ListItemButton
                                sx={{ display: 'list-item' }}
                                onClick={() => {
                                    document
                                        .getElementsByClassName(
                                            'controls-for-do-not-track-features',
                                        )[0]
                                        ?.scrollIntoView({
                                            behavior: 'smooth',
                                            inline: 'nearest',
                                        });
                                }}
                            >
                                <ListItemText primary="CONTROLS FOR DO-NOT-TRACK FEATURES" />
                            </ListItemButton>
                            <ListItemButton
                                sx={{ display: 'list-item' }}
                                onClick={() => {
                                    document
                                        .getElementsByClassName(
                                            'do-california-residents-have-specific-privacy-rights',
                                        )[0]
                                        ?.scrollIntoView({
                                            behavior: 'smooth',
                                            inline: 'nearest',
                                        });
                                }}
                            >
                                <ListItemText primary="DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?" />
                            </ListItemButton>
                            <ListItemButton
                                sx={{ display: 'list-item' }}
                                onClick={() => {
                                    document
                                        .getElementsByClassName(
                                            'do-we-make-updates-to-this-notice',
                                        )[0]
                                        ?.scrollIntoView({
                                            behavior: 'smooth',
                                            inline: 'nearest',
                                        });
                                }}
                            >
                                <ListItemText primary="DO WE MAKE UPDATES TO THIS NOTICE?" />
                            </ListItemButton>
                            <ListItemButton
                                sx={{ display: 'list-item' }}
                                onClick={() => {
                                    document
                                        .getElementsByClassName(
                                            'how-can-you-contact-us-about-this-notice',
                                        )[0]
                                        ?.scrollIntoView({
                                            behavior: 'smooth',
                                            inline: 'nearest',
                                        });
                                }}
                            >
                                <ListItemText primary="HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" />
                            </ListItemButton>
                            <ListItemButton
                                sx={{ display: 'list-item' }}
                                onClick={() => {
                                    document
                                        .getElementsByClassName(
                                            'how-can-you-review-update-or-delete-the-data-we-collect-from-you',
                                        )[0]
                                        ?.scrollIntoView({
                                            behavior: 'smooth',
                                            inline: 'nearest',
                                        });
                                }}
                            >
                                <ListItemText primary="HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?" />
                            </ListItemButton>
                        </List>
                    </Paper>

                    <Paper
                        sx={{ p: 2 }}
                        className="what-information-do-we-collect"
                    >
                        <Typography variant="h2" fontWeight={'bold'}>
                            1. WHAT INFORMATION DO WE COLLECT?
                        </Typography>
                        <Divider sx={{ marginBottom: '1rem' }} light />
                        <Typography variant="h4" fontWeight={'bold'}>
                            Personal information you disclose to us
                        </Typography>
                        <Typography
                            variant="h5"
                            color={colors.greenAccent[400]}
                            fontWeight={'bold'}
                        >
                            In Short: We collect personal information that you
                            provide to us.
                        </Typography>
                        <Typography variant="body1">
                            We collect personal information that you voluntarily
                            provide to us when you register on the Services,
                            express an interest in obtaining information about
                            us or our products and Services, when you
                            participate in activities on the Services, or
                            otherwise when you contact us.
                        </Typography>
                        <Typography variant="body1">
                            The personal information that we collect depends on
                            the context of your interactions with us and the
                            Services, the choices you make, and the products and
                            features you use. The personal information we
                            collect may include the following:
                        </Typography>
                        <List sx={{ listStyleType: 'disc', pl: 4 }}>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="email addresses" />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="usernames" />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="passwords" />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="contact preferences" />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="contact or authentication data" />
                            </ListItem>
                        </List>
                        <Typography
                            variant="h5"
                            color={colors.greenAccent[400]}
                            fontWeight={'bold'}
                        >
                            Sensitive Information
                        </Typography>
                        <Typography variant="body1">
                            We do not process sensitive information.
                        </Typography>
                        <br />
                        <Typography
                            variant="h5"
                            color={colors.greenAccent[400]}
                            fontWeight={'bold'}
                        >
                            Social Media Login Data
                        </Typography>
                        <Typography variant="body1">
                            We may provide you with the option to register with
                            us using your existing social media account details,
                            like your Facebook, Twitter, or other social media
                            account. If you choose to register in this way, we
                            will collect the information described in the
                            section called &apos;HOW DO WE HANDLE YOUR SOCIAL
                            LOGINS?&apos; below.
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            All personal information that you provide to us must
                            be true, complete, and accurate, and you must notify
                            us of any changes to such personal information.
                        </Typography>
                        <br />
                        <Typography variant="h4" fontWeight={'bold'}>
                            Information automatically collected
                        </Typography>
                        <Typography
                            variant="h5"
                            color={colors.greenAccent[400]}
                            fontWeight={'bold'}
                        >
                            In Short: Some information — such as your Internet
                            Protocol (IP) address and/or browser and device
                            characteristics — is collected automatically when
                            you visit our Services.
                        </Typography>
                        <Typography variant="body1">
                            We automatically collect certain information when
                            you visit, use, or navigate the Services. This
                            information does not reveal your specific identity
                            (like your name or contact information) but may
                            include device and usage information, such as your
                            IP address, browser and device characteristics,
                            operating system, language preferences, referring
                            URLs, device name, country, location, information
                            about how and when you use our Services, and other
                            technical information. This information is primarily
                            needed to maintain the security and operation of our
                            Services, and for our internal analytics and
                            reporting purposes.
                        </Typography>
                        <br />
                        <Typography variant="h4" fontWeight={'bold'}>
                            Like many businesses, we also collect information
                            through cookies and similar technologies.
                        </Typography>
                        <Typography
                            variant="h5"
                            color={colors.greenAccent[400]}
                            fontWeight={'bold'}
                        >
                            In Short: We may collect information using cookies
                            and similar technologies.
                        </Typography>
                        <Typography variant="body1">
                            The information we collect includes:
                        </Typography>
                        <List sx={{ listStyleType: 'disc', pl: 4 }}>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps') and hardware settings)." />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system and system configuration information." />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services." />
                            </ListItem>
                        </List>
                    </Paper>

                    <Paper
                        sx={{ p: 2 }}
                        className="how-do-we-process-your-information"
                    >
                        <Typography variant="h2" fontWeight={'bold'}>
                            2. HOW DO WE PROCESS YOUR INFORMATION?
                        </Typography>
                        <Divider sx={{ marginBottom: '1rem' }} light />
                        <Typography
                            variant="h5"
                            color={colors.greenAccent[400]}
                            fontWeight={'bold'}
                        >
                            In Short: We process your information to provide,
                            improve, and administer our Services, communicate
                            with you, for security and fraud prevention, and to
                            comply with law. We may also process your
                            information for other purposes with your consent.
                        </Typography>
                        <Typography variant="body1">
                            We process your personal information for a variety
                            of reasons, depending on how you interact with our
                            Services, including:
                        </Typography>
                        <List sx={{ listStyleType: 'disc', pl: 4 }}>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order." />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="To protect our Services. We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention." />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="To save or protect an individual's vital interest. We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm." />
                            </ListItem>
                        </List>
                    </Paper>

                    <Paper
                        sx={{ p: 2 }}
                        className="what-legal-bases-do-we-rely-on-to-process-your-information"
                    >
                        <Typography variant="h2" fontWeight={'bold'}>
                            3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR
                            INFORMATION?
                        </Typography>
                        <Divider sx={{ marginBottom: '1rem' }} light />
                        <Typography
                            variant="h5"
                            color={colors.greenAccent[400]}
                            fontWeight={'bold'}
                        >
                            In Short: We only process your personal information
                            when we believe it is necessary and we have a valid
                            legal reason (i.e. legal basis) to do so under
                            applicable law, like with your consent, to comply
                            with laws, to provide you with services to enter
                            into or fulfil our contractual obligations, to
                            protect your rights, or to fulfil our legitimate
                            business interests.
                        </Typography>
                        <Typography variant="body1" fontWeight={'bold'}>
                            If you are located in the EU or UK, this section
                            applies to you.
                        </Typography>
                        <Typography variant="body1">
                            The General Data Protection Regulation (GDPR) and UK
                            GDPR require us to explain the valid legal bases we
                            rely on in order to process your personal
                            information. As such, we may rely on the following
                            legal bases to process your personal information:
                        </Typography>
                        <List sx={{ listStyleType: 'disc', pl: 4 }}>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="Consent. We may process your information if you have given us permission (i.e. consent) to use your personal information for a specific purpose. You can withdraw your consent at any time." />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="Legitimate Interests. We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to: Diagnose problems and/or prevent fraudulent activities" />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="Legal Obligations. We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved." />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="Vital Interests. We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person." />
                            </ListItem>
                        </List>
                        <br />
                        <Typography variant="body1" fontWeight={'bold'}>
                            If you are located in Canada, this section applies
                            to you.
                        </Typography>
                        <Typography variant="body1">
                            We may process your information if you have given us
                            specific permission (i.e. express consent) to use
                            your personal information for a specific purpose, or
                            in situations where your permission can be inferred
                            (i.e. implied consent). You can withdraw your
                            consent at any time.
                        </Typography>
                        <Typography variant="body1">
                            In some exceptional cases, we may be legally
                            permitted under applicable law to process your
                            information without your consent, including, for
                            example:
                        </Typography>
                        <List sx={{ listStyleType: 'disc', pl: 4 }}>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way" />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="For investigations and fraud detection and prevention" />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="For business transactions provided certain conditions are met" />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim" />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="For identifying injured, ill, or deceased persons and communicating with next of kin" />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse" />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province" />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records" />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced" />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="If the collection is solely for journalistic, artistic, or literary purposes" />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="If the information is publicly available and is specified by the regulations" />
                            </ListItem>
                        </List>
                    </Paper>

                    <Paper
                        sx={{ p: 2 }}
                        className="when-and-with-whom-do-we-share-your-personal-information"
                    >
                        <Typography variant="h2" fontWeight={'bold'}>
                            4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                            INFORMATION?
                        </Typography>
                        <Divider sx={{ marginBottom: '1rem' }} light />
                        <Typography
                            variant="h5"
                            color={colors.greenAccent[400]}
                            fontWeight={'bold'}
                        >
                            In Short: We may share information in specific
                            situations described in this section and/or with the
                            following third parties.
                        </Typography>
                        <Typography variant="body1">
                            We may need to share your personal information in
                            the following situations:
                        </Typography>
                        <List sx={{ listStyleType: 'disc', pl: 4 }}>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company." />
                            </ListItem>
                        </List>
                    </Paper>

                    <Paper
                        sx={{ p: 2 }}
                        className="do-we-use-cookies-and-other-tracking-technologies"
                    >
                        <Typography variant="h2" fontWeight={'bold'}>
                            5. DO WE USE COOKIES AND OTHER TRACKING
                            TECHNOLOGIES?
                        </Typography>
                        <Divider sx={{ marginBottom: '1rem' }} light />
                        <Typography
                            variant="h5"
                            color={colors.greenAccent[400]}
                            fontWeight={'bold'}
                        >
                            In Short: We may use cookies and other tracking
                            technologies to collect and store your information.
                        </Typography>
                        <Typography variant="body1">
                            We may use cookies and similar tracking technologies
                            (like web beacons and pixels) to access or store
                            information. Specific information about how we use
                            such technologies and how you can refuse certain
                            cookies is set out in our Cookie Notice.
                        </Typography>
                    </Paper>

                    <Paper
                        sx={{ p: 2 }}
                        className="how-do-we-handle-your-social-logins"
                    >
                        <Typography variant="h2" fontWeight={'bold'}>
                            6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                        </Typography>
                        <Divider sx={{ marginBottom: '1rem' }} light />
                        <Typography
                            variant="h5"
                            color={colors.greenAccent[400]}
                            fontWeight={'bold'}
                        >
                            In Short: If you choose to register or log in to our
                            services using a social media account, we may have
                            access to certain information about you.
                        </Typography>
                        <Typography variant="body1">
                            Our Services offer you the ability to register and
                            log in using your third-party social media account
                            details (like your Facebook or Twitter logins).
                            Where you choose to do this, we will receive certain
                            profile information about you from your social media
                            provider. The profile information we receive may
                            vary depending on the social media provider
                            concerned, but will often include your name, email
                            address, friends list, and profile picture, as well
                            as other information you choose to make public on
                            such a social media platform.
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            We will use the information we receive only for the
                            purposes that are described in this privacy notice
                            or that are otherwise made clear to you on the
                            relevant Services. Please note that we do not
                            control, and are not responsible for, other uses of
                            your personal information by your third-party social
                            media provider. We recommend that you review their
                            privacy notice to understand how they collect, use,
                            and share your personal information, and how you can
                            set your privacy preferences on their sites and
                            apps.
                        </Typography>
                    </Paper>

                    <Paper
                        sx={{ p: 2 }}
                        className="how-long-do-we-keep-your-information"
                    >
                        <Typography variant="h2" fontWeight={'bold'}>
                            7. HOW LONG DO WE KEEP YOUR INFORMATION?
                        </Typography>
                        <Divider sx={{ marginBottom: '1rem' }} light />
                        <Typography
                            variant="h5"
                            color={colors.greenAccent[400]}
                            fontWeight={'bold'}
                        >
                            In Short: We keep your information for as long as
                            necessary to fulfil the purposes outlined in this
                            privacy notice unless otherwise required by law.
                        </Typography>
                        <Typography variant="body1">
                            We will only keep your personal information for as
                            long as it is necessary for the purposes set out in
                            this privacy notice, unless a longer retention
                            period is required or permitted by law (such as tax,
                            accounting, or other legal requirements). No purpose
                            in this notice will require us keeping your personal
                            information for longer than the period of time in
                            which users have an account with us.
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            When we have no ongoing legitimate business need to
                            process your personal information, we will either
                            delete or anonymise such information, or, if this is
                            not possible (for example, because your personal
                            information has been stored in backup archives),
                            then we will securely store your personal
                            information and isolate it from any further
                            processing until deletion is possible.
                        </Typography>
                    </Paper>

                    <Paper
                        sx={{ p: 2 }}
                        className="how-do-we-keep-your-information-safe"
                    >
                        <Typography variant="h2" fontWeight={'bold'}>
                            8. HOW DO WE KEEP YOUR INFORMATION SAFE?
                        </Typography>
                        <Divider sx={{ marginBottom: '1rem' }} light />
                        <Typography
                            variant="h5"
                            color={colors.greenAccent[400]}
                            fontWeight={'bold'}
                        >
                            In Short: We aim to protect your personal
                            information through a system of organisational and
                            technical security measures.
                        </Typography>
                        <Typography variant="body1">
                            We have implemented appropriate and reasonable
                            technical and organisational security measures
                            designed to protect the security of any personal
                            information we process. However, despite our
                            safeguards and efforts to secure your information,
                            no electronic transmission over the Internet or
                            information storage technology can be guaranteed to
                            be 100% secure, so we cannot promise or guarantee
                            that hackers, cybercriminals, or other unauthorised
                            third parties will not be able to defeat our
                            security and improperly collect, access, steal, or
                            modify your information. Although we will do our
                            best to protect your personal information,
                            transmission of personal information to and from our
                            Services is at your own risk. You should only access
                            the Services within a secure environment.
                        </Typography>
                    </Paper>

                    <Paper
                        sx={{ p: 2 }}
                        className="what-are-your-privacy-rights"
                    >
                        <Typography variant="h2" fontWeight={'bold'}>
                            9. WHAT ARE YOUR PRIVACY RIGHTS?
                        </Typography>
                        <Divider sx={{ marginBottom: '1rem' }} light />
                        <Typography
                            variant="h5"
                            color={colors.greenAccent[400]}
                            fontWeight={'bold'}
                        >
                            In Short: In some regions, such as the European
                            Economic Area (EEA), United Kingdom (UK), and
                            Canada, you have rights that allow you greater
                            access to and control over your personal
                            information. You may review, change, or terminate
                            your account at any time.
                        </Typography>
                        <Typography variant="body1">
                            In some regions (like the EEA, UK, and Canada), you
                            have certain rights under applicable data protection
                            laws. These may include the right{' '}
                        </Typography>
                        <List sx={{ listStyleType: 'lower-roman', pl: 4 }}>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="to request access and obtain a copy of your personal information," />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="to request rectification or erasure;" />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="to restrict the processing of your personal information; and" />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="if applicable, to data portability." />
                            </ListItem>
                        </List>
                        <Paper variant="outlined" sx={{ p: 2 }}>
                            <Typography variant="body1">
                                In certain circumstances, you may also have the
                                right to object to the processing of your
                                personal information. You can make such a
                                request by contacting us by using the contact
                                details provided in the section &apos;HOW CAN
                                YOU CONTACT US ABOUT THIS NOTICE?&apos; below.
                            </Typography>
                            <Typography variant="body1">
                                We will consider and act upon any request in
                                accordance with applicable data protection laws.
                            </Typography>
                        </Paper>
                        <br />
                        <Typography variant="body1">
                            If you are located in the EEA or UK and you believe
                            we are unlawfully processing your personal
                            information, you also have the right to complain to
                            your local data protection supervisory authority.
                            You can find their contact details here:{' '}
                            <a
                                target="_blank"
                                href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                rel="noreferrer"
                            >
                                https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                            </a>
                            .
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            If you are located in Switzerland, the contact
                            details for the data protection authorities are
                            available here:{' '}
                            <a
                                target="_blank"
                                href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                rel="noreferrer"
                            >
                                https://www.edoeb.admin.ch/edoeb/en/home.html
                            </a>
                            .
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            Withdrawing your consent: If we are relying on your
                            consent to process your personal information, which
                            may be express and/or implied consent depending on
                            the applicable law, you have the right to withdraw
                            your consent at any time. You can withdraw your
                            consent at any time by contacting us by using the
                            contact details provided in the section &apos;HOW
                            CAN YOU CONTACT US ABOUT THIS NOTICE?&apos; below.
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            However, please note that this will not affect the
                            lawfulness of the processing before its withdrawal,
                            nor when applicable law allows, will it affect the
                            processing of your personal information conducted in
                            reliance on lawful processing grounds other than
                            consent.
                        </Typography>
                        <br />
                        <Typography variant="body1" fontWeight={'bold'}>
                            Account Information
                        </Typography>
                        <Typography variant="body1">
                            If you would at any time like to review or change
                            the information in your account or terminate your
                            account, you can:
                        </Typography>
                        <List sx={{ listStyleType: 'disc', pl: 4 }}>
                            <ListItem sx={{ display: 'list-item' }}>
                                <ListItemText primary="Contact us using the contact information provided." />
                            </ListItem>
                        </List>
                        <Typography variant="body1">
                            Upon your request to terminate your account, we will
                            deactivate or delete your account and information
                            from our active databases. However, we may retain
                            some information in our files to prevent fraud,
                            troubleshoot problems, assist with any
                            investigations, enforce our legal terms and/or
                            comply with applicable legal requirements.
                        </Typography>
                        <br />
                        <Typography variant="body1" fontWeight={'bold'}>
                            Cookies and similar technologies:
                        </Typography>
                        <Typography variant="body1">
                            Most Web browsers are set to accept cookies by
                            default. If you prefer, you can usually choose to
                            set your browser to remove cookies and to reject
                            cookies. If you choose to remove cookies or reject
                            cookies, this could affect certain features or
                            services of our Services. To opt out of
                            interest-based advertising by advertisers on our
                            Services visit{' '}
                            <a
                                target="_blank"
                                href="http://www.aboutads.info/choices/"
                                rel="noreferrer"
                            >
                                http://www.aboutads.info/choices/
                            </a>
                            .
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            If you have questions or comments about your privacy
                            rights, you may email us by using the contact
                            details provided in the section &apos;HOW CAN YOU
                            CONTACT US ABOUT THIS NOTICE?&apos; below.
                        </Typography>
                    </Paper>

                    <Paper
                        sx={{ p: 2 }}
                        className="controls-for-do-not-track-features"
                    >
                        <Typography variant="h2" fontWeight={'bold'}>
                            10. CONTROLS FOR DO-NOT-TRACK FEATURES
                        </Typography>
                        <Divider sx={{ marginBottom: '1rem' }} light />
                        <Typography variant="body1">
                            Most web browsers and some mobile operating systems
                            and mobile applications include a Do-Not-Track
                            (&apos;DNT&apos;) feature or setting you can
                            activate to signal your privacy preference not to
                            have data about your online browsing activities
                            monitored and collected. At this stage no uniform
                            technology standard for recognising and implementing
                            DNT signals has been finalised. As such, we do not
                            currently respond to DNT browser signals or any
                            other mechanism that automatically communicates your
                            choice not to be tracked online. If a standard for
                            online tracking is adopted that we must follow in
                            the future, we will inform you about that practice
                            in a revised version of this privacy notice.
                        </Typography>
                    </Paper>

                    <Paper
                        sx={{ p: 2 }}
                        className="do-california-residents-have-specific-privacy-rights"
                    >
                        <Typography variant="h2" fontWeight={'bold'}>
                            11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY
                            RIGHTS?
                        </Typography>
                        <Divider sx={{ marginBottom: '1rem' }} light />
                        <Typography
                            variant="h5"
                            color={colors.greenAccent[400]}
                            fontWeight={'bold'}
                        >
                            In Short: Yes, if you are a resident of California,
                            you are granted specific rights regarding access to
                            your personal information.
                        </Typography>
                        <Typography variant="body1">
                            California Civil Code Section 1798.83, also known as
                            the &apos;Shine The Light&apos; law, permits our
                            users who are California residents to request and
                            obtain from us, once a year and free of charge,
                            information about categories of personal information
                            (if any) we disclosed to third parties for direct
                            marketing purposes and the names and addresses of
                            all third parties with which we shared personal
                            information in the immediately preceding calendar
                            year. If you are a California resident and would
                            like to make such a request, please submit your
                            request in writing to us using the contact
                            information provided below.
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            If you are under 18 years of age, reside in
                            California, and have a registered account with
                            Services, you have the right to request removal of
                            unwanted data that you publicly post on the
                            Services. To request removal of such data, please
                            contact us using the contact information provided
                            below and include the email address associated with
                            your account and a statement that you reside in
                            California. We will make sure the data is not
                            publicly displayed on the Services, but please be
                            aware that the data may not be completely or
                            comprehensively removed from all our systems (e.g.
                            backups, etc.).
                        </Typography>
                    </Paper>

                    <Paper
                        sx={{ p: 2 }}
                        className="do-we-make-updates-to-this-notice"
                    >
                        <Typography variant="h2" fontWeight={'bold'}>
                            12. DO WE MAKE UPDATES TO THIS NOTICE?
                        </Typography>
                        <Divider sx={{ marginBottom: '1rem' }} light />
                        <Typography
                            variant="h5"
                            color={colors.greenAccent[400]}
                            fontWeight={'bold'}
                        >
                            In Short: Yes, we will update this notice as
                            necessary to stay compliant with relevant laws.
                        </Typography>
                        <Typography variant="body1">
                            We may update this privacy notice from time to time.
                            The updated version will be indicated by an updated
                            &apos;Revised&apos; date and the updated version
                            will be effective as soon as it is accessible. If we
                            make material changes to this privacy notice, we may
                            notify you either by prominently posting a notice of
                            such changes or by directly sending you a
                            notification. We encourage you to review this
                            privacy notice frequently to be informed of how we
                            are protecting your information.
                        </Typography>
                    </Paper>

                    <Paper
                        sx={{ p: 2 }}
                        className="how-can-you-contact-us-about-this-notice"
                    >
                        <Typography variant="h2" fontWeight={'bold'}>
                            13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                        </Typography>
                        <Divider sx={{ marginBottom: '1rem' }} light />
                        <Typography variant="body1">
                            If you have questions or comments about this notice,
                            you may contact us through our listed Discord
                            communications channels.
                        </Typography>
                    </Paper>

                    <Paper
                        sx={{ p: 2 }}
                        className="how-can-you-review-update-or-delete-the-data-we-collect-from-you"
                    >
                        <Typography variant="h2" fontWeight={'bold'}>
                            14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA
                            WE COLLECT FROM YOU?
                        </Typography>
                        <Divider sx={{ marginBottom: '1rem' }} light />
                        <Typography variant="body1">
                            Based on the applicable laws of your country, you
                            may have the right to request access to the personal
                            information we collect from you, change that
                            information, or delete it. To request to review,
                            update, or delete your personal information, please
                            contact us.
                        </Typography>
                    </Paper>
                </Box>
            </Box>
        </Box>
    );
};

export default PrivacyPolicy;
