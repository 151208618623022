import React, { createContext, useState, useContext, useEffect } from 'react';

import axios, { AxiosResponse } from 'axios';
import { io, type Socket } from 'socket.io-client';
import { type Id as ToastId, toast, type ToastContent } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

export const NotificationContext = createContext({} as any);

export const NotificationContextProvider = ({ children }: any): JSX.Element => {
    const [socket, setSocket] = useState({} as any);
    const [notifications, setNotifications] = useState([] as any[]);
    let [progressToasts, setProgressToasts] = useState([] as any[]);

    const dispatch = useDispatch();
    const { userInfo } = useSelector((state: any) => state.auth);

    useEffect(() => {
        if (!userInfo) return;
        try {
            const newSocket = io(process.env.REACT_APP_API_URI as string, {
                withCredentials: true,
            });
            setSocket(newSocket);
            return () => {
                newSocket.disconnect();
            };
        } catch (err) {
            console.log(err);
        }
    }, [userInfo]);

    useEffect(() => {
        if ((socket as Socket) && (socket as Socket).connected) {
            try {
                socket.on('toast', (data: any) => {
                    if (!data && !data.progress) return;
                    toast(data.message as ToastContent<unknown>, {
                        type: data.type,
                        icon: data.icon,
                    });
                });

                socket.on('toast-progress', (data: any) => {
                    if (!data) return;
                    let progressToast = progressToasts.find((t) => {
                        return t.filename === data.filename;
                    });
                    if (progressToast) {
                        toast.update(progressToast.toastID as ToastId, {
                            progress: data.minProgress / data.maxProgress,
                        });
                        if (data.minProgress === data.maxProgress) {
                            progressToasts = progressToasts.filter((t) => {
                                return t.filename !== data.filename;
                            });
                        }
                    } else {
                        progressToast = {
                            toastID: toast(
                                data.message as ToastContent<unknown>,
                                {
                                    type: data.type,
                                    icon: data.icon,
                                    progress:
                                        data.minProgress / data.maxProgress,
                                },
                            ),
                            filename: data.filename,
                        };
                        progressToasts.push(progressToast);
                    }
                });

                socket.on('console', (data: any) => {
                    if (!data) return;
                    console.log(data.message);
                });
            } catch (err) {}
        }
    }, [socket]);

    useEffect(() => {
        if (notifications && notifications.length > 0) {
            notifications.forEach((notification: any) => {
                if (notification.type === 'toast') {
                    toast(notification.message as ToastContent<unknown>, {
                        type: notification.toastType,
                        icon: notification.icon,
                    });
                }
            });
        }
    }, [notifications]);

    function addNotification(notification: any): void {
        setNotifications([...notifications, notification]);
    }

    return (
        <NotificationContext.Provider
            value={{
                notifications,
                addNotification,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};
