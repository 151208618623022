import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
// import { toast, ExternalToast } from "sonner";
import { useNotificationCenter } from 'react-toastify/addons/use-notification-center';

import {
    Badge,
    Box,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Typography,
    useTheme,
    Alert,
    type AlertColor,
    Popper,
    Fade,
    Button,
    FormGroup,
    FormControlLabel,
    Switch,
    Stack,
    ClickAwayListener,
    darken,
    InputBase,
} from '@mui/material';

import {
    LightModeOutlined,
    DarkModeOutlined,
    NotificationsOutlined,
    SettingsOutlined,
    PersonOutlined,
    Search,
    ClearAll,
    MarkChatRead,
    Check,
    Logout,
    LockOpen,
} from '@mui/icons-material';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLogoutMutation } from '@src/slices/authApiSlice';
import { logout, setCredentials } from '@src/slices/authSlice';

import { ColorModeContext, getColors } from '@src/global/context/theme';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Topbar = (): JSX.Element => {
    const theme = useTheme();
    const colors = getColors(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userInfo } = useSelector((state: any) => state.auth, shallowEqual);

    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { notifications, clear, markAllAsRead, markAsRead, unreadCount } =
        useNotificationCenter();

    const [userAnchorElement, setUserAnchorElement] = React.useState(null);
    const [
        notificationAnchorElement,
        setNotificationAnchorElement,
    ]: React.SetStateAction<any> = React.useState(null);
    const [showUnreadOnly, setShowUnreadOnly] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);

    // const handleUserClick = (event: React.MouseEvent): void => {
    //     setUserAnchorElement(event.currentTarget);
    // };

    const [logoutApiCall] = useLogoutMutation();

    const logoutHandler = async (): Promise<void> => {
        try {
            // let res = await logoutApiCall({}).unwrap();
            dispatch(logout());
            navigate('/');
            toast.success('Successfully logged out');
        } catch (e) {
            toast.error('Unhandeled error occured while logging out');
            console.log(e);
        }
    };

    const toggleNotificationCenter = (event: React.MouseEvent): void => {
        setNotificationAnchorElement(event.currentTarget);
        setIsOpen(!isOpen);
    };

    const toggleFilter = (e: React.ChangeEvent): void => {
        setShowUnreadOnly(!showUnreadOnly);
    };

    const loginWithRoblox = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ): void => {
        window.open(process.env.REACT_APP_API_URI + '/v1/auth/roblox', '_self');
    };

    // let addNotification = () => {
    //   // use a random type of notification
    //   const types = ["success", "info", "warning", "error"];

    //   toast("Lorem ipsum dolor sit amet, consectetur adipiscing elit", {
    //     type: types[Math.floor(Math.random() * types.length)] as TypeOptions
    //   });
    // };

    return (
        <Box display="flex" alignItems="center" justifyContent="right" p={2}>
            {' '}
            {/* justifyContent="space-between" */}
            {/* SEARCH BAR */}
            {/* <Box sx={{ display: "flex", backgroundColor: colors.primary[400], borderRadius: "3px" }}>
        <InputBase sx={{ ml: 2, flex: 1}} placeholder="Search" />

        <IconButton type="button" sx={{ p: 1 }}>
          <Search />
        </IconButton>
      </Box> */}
            {/* RIGHT SIDE */}
            <Box display="flex" alignItems="right">
                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === 'dark' ? (
                        <DarkModeOutlined />
                    ) : (
                        <LightModeOutlined />
                    )}
                </IconButton>
                {/* <button onClick={addNotification}>Add notification</button> */}

                {userInfo ? (
                    <>
                        {/* <IconButton>
              <SettingsOutlined />
            </IconButton>

            <IconButton onClick={handleUserClick}>
              <PersonOutlined />
            </IconButton> */}

                        <IconButton onClick={toggleNotificationCenter}>
                            <Badge
                                sx={{
                                    '& .MuiBadge-badge': {
                                        right: -3,
                                        top: 20,
                                        border: `2px solid ${colors.primary[500]}`,
                                        fontSize: '0.8rem',
                                    },
                                }}
                                badgeContent={unreadCount}
                                color="error"
                            >
                                <NotificationsOutlined />
                            </Badge>
                        </IconButton>

                        {/* <Menu
              id="user-menu"
              anchorEl={userAnchorElement}
              keepMounted
              open={Boolean(userAnchorElement)}
              onClose={() => setUserAnchorElement(null)}
            >
              <MenuList>
                <MenuItem onClick={logoutHandler}>
                  <ListItemIcon>
                    <Logout />
                  </ListItemIcon>
                  <ListItemText>Logout</ListItemText>
                </MenuItem>
              </MenuList>
            </Menu> */}

                        <Popper
                            sx={{
                                zIndex: 9999,
                            }}
                            open={isOpen}
                            anchorEl={notificationAnchorElement}
                            transition
                        >
                            {({ TransitionProps }) => (
                                <ClickAwayListener
                                    onClickAway={() => {
                                        setIsOpen(false);
                                    }}
                                >
                                    <Fade {...TransitionProps} timeout={350}>
                                        <Box>
                                            <Box
                                                sx={{
                                                    background:
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? colors
                                                                  .blueAccent[600]
                                                            : colors
                                                                  .blueAccent[800],
                                                    marginBottom: '8px',
                                                    padding: '8px',
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Typography
                                                    variant="h5"
                                                    color={colors.grey[100]}
                                                    fontWeight="bold"
                                                >
                                                    Notification center
                                                </Typography>
                                                <FormGroup
                                                    sx={{
                                                        color: colors.grey[100],
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                color="secondary"
                                                                onChange={
                                                                    toggleFilter
                                                                }
                                                                checked={
                                                                    showUnreadOnly
                                                                }
                                                            />
                                                        }
                                                        label="Show unread only"
                                                    />
                                                </FormGroup>
                                            </Box>
                                            <Stack
                                                sx={{
                                                    height: '400px',
                                                    width: 'min(60ch, 100ch)',
                                                    padding: '12px',
                                                    background: darken(
                                                        colors.primary[400],
                                                        0.2,
                                                    ),
                                                    borderRadius: '8px',
                                                    overflowY: 'auto',
                                                }}
                                                spacing={2}
                                            >
                                                {(!notifications.length ||
                                                    (unreadCount === 0 &&
                                                        showUnreadOnly)) && (
                                                    <Typography
                                                        variant="h4"
                                                        sx={{
                                                            fontWeight: 'bold',
                                                            color: colors
                                                                .grey[100],
                                                        }}
                                                    >
                                                        Your queue is empty!{' '}
                                                        <span
                                                            role="img"
                                                            aria-label="dunno what to put"
                                                        >
                                                            🧁
                                                        </span>
                                                    </Typography>
                                                )}
                                                {(showUnreadOnly
                                                    ? notifications.filter(
                                                          (v) => !v.read,
                                                      )
                                                    : notifications
                                                ).map((notification) => {
                                                    return (
                                                        <Alert
                                                            key={
                                                                notification.id
                                                            }
                                                            severity={
                                                                (notification.type as AlertColor) ||
                                                                'info'
                                                            }
                                                            action={
                                                                notification.read ? (
                                                                    <Check />
                                                                ) : (
                                                                    <IconButton
                                                                        color="primary"
                                                                        aria-label="upload picture"
                                                                        component="span"
                                                                        onClick={() => {
                                                                            markAsRead(
                                                                                notification.id,
                                                                            );
                                                                        }}
                                                                    >
                                                                        <MarkChatRead
                                                                            sx={{
                                                                                color: colors
                                                                                    .greenAccent[300],
                                                                            }}
                                                                        />
                                                                    </IconButton>
                                                                )
                                                            }
                                                        >
                                                            {
                                                                notification.content as any
                                                            }
                                                        </Alert>
                                                    );
                                                })}
                                            </Stack>
                                            <Box
                                                sx={{
                                                    background:
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? colors
                                                                  .blueAccent[600]
                                                            : colors
                                                                  .blueAccent[800],
                                                    marginTop: '8px',
                                                    padding: '8px',
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    onClick={clear}
                                                    sx={{
                                                        backgroundColor:
                                                            colors.primary[700],
                                                        color:
                                                            theme.palette
                                                                .mode === 'dark'
                                                                ? colors
                                                                      .grey[100]
                                                                : colors
                                                                      .grey[900],
                                                        '&:hover': {
                                                            backgroundColor:
                                                                colors
                                                                    .greenAccent[600],
                                                            color:
                                                                theme.palette
                                                                    .mode ===
                                                                'light'
                                                                    ? colors
                                                                          .grey[100]
                                                                    : colors
                                                                          .grey[900],
                                                        },
                                                    }}
                                                >
                                                    <ClearAll
                                                        sx={{
                                                            color: colors
                                                                .redAccent[300],
                                                        }}
                                                    />{' '}
                                                    &nbsp; Clear All
                                                </Button>

                                                <Button
                                                    variant="contained"
                                                    onClick={markAllAsRead}
                                                    sx={{
                                                        backgroundColor:
                                                            colors.primary[700],
                                                        color:
                                                            theme.palette
                                                                .mode === 'dark'
                                                                ? colors
                                                                      .grey[100]
                                                                : colors
                                                                      .grey[900],
                                                        '&:hover': {
                                                            backgroundColor:
                                                                colors
                                                                    .greenAccent[600],
                                                            color:
                                                                theme.palette
                                                                    .mode ===
                                                                'light'
                                                                    ? colors
                                                                          .grey[100]
                                                                    : colors
                                                                          .grey[900],
                                                        },
                                                    }}
                                                >
                                                    <MarkChatRead
                                                        sx={{
                                                            color: colors
                                                                .greenAccent[300],
                                                        }}
                                                    />{' '}
                                                    &nbsp; Mark all as read
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Fade>
                                </ClickAwayListener>
                            )}
                        </Popper>
                    </>
                ) : (
                    <>
                        {/* <Box component="form" onSubmit={loginWithDiscord} noValidate sx={{ mt: 1 }}> */}
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{
                                ml: 2,
                                width: '12rem',
                                backgroundColor: colors.blueAccent[400],
                            }}
                            onClick={loginWithRoblox}
                        >
                            {/* <FontAwesomeIcon icon={faDiscord} /> */}
                            <LockOpen /> &nbsp; Signin with Roblox
                        </Button>
                        {/* </Box> */}
                    </>
                )}
            </Box>
        </Box>
    );
};

export default Topbar;
