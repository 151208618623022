import { useState } from 'react';
import {
    Box,
    Button,
    IconButton,
    Typography,
    useTheme,
    Grid,
    Link,
    Card,
    Paper,
} from '@mui/material';
import { getColors } from '@src/global/context/theme';

import Header from '@src/global/components/Header';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Carousel from 'react-material-ui-carousel';
import { Image, Transform } from '@mui/icons-material';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

const Dashboard = (): JSX.Element => {
    const theme = useTheme();
    const colors = getColors(theme.palette.mode);

    const { userInfo } = useSelector((state: any) => state.auth, shallowEqual);

    const items = [
        {
            img: '/assets/carousel/1.png',
        },
        // {
        //     name: "Welcome to the Roblox United States of America",
        //     description: "Become a police officer and enforce the law.",
        //     img: "/assets/carousel/1.png"
        // },
        // {
        //     name: "Welcome to the Roblox United States of America",
        //     description: "Become a transit operator.",
        //     img: "/assets/carousel/2.png"
        // },
        // {
        //     name: "Welcome to the Roblox United States of America",
        //     description: "Join the National Guard and protect the state.",
        //     img: "/assets/carousel/3.png"
        // },
        // {
        //     name: "Welcome to the Roblox United States of America",
        //     description: "Direct traffic.",
        //     img: "/assets/carousel/4.png"
        // },
        // {
        //     name: "Welcome to the Roblox United States of America",
        //     description: "Deliver packages.",
        //     img: "/assets/carousel/5.png"
        // }
    ];
    function Item(props: any): JSX.Element {
        return (
            <>
                <Paper
                    sx={{
                        display: 'flex',
                        width: '100%',
                        height: '35vh',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '10px',
                        overflow: 'hidden',
                    }}
                >
                    <Typography
                        sx={{
                            position: 'absolute',
                            // top: "0px",
                            mt: 'auto',
                            mb: '80px',
                            ml: 'auto',
                            padding: '10px',
                            color: 'white',
                            fontWeight: 'bold',
                        }}
                        variant="h1"
                    >
                        {props.item.name}
                    </Typography>
                    <Typography
                        sx={{
                            position: 'absolute',
                            // top: "0px",
                            mt: 'auto',
                            mb: '10px',
                            ml: 'auto',
                            padding: '10px',
                            color: 'white',
                            // fontWeight: "bold"
                        }}
                        variant="h2"
                    >
                        {props.item.description}
                    </Typography>
                    <Box
                        component="img"
                        sx={{
                            display: 'flex',
                            // justifyContent: "center",
                            overflow: 'hidden',
                            width: '100%',
                        }}
                        loading="lazy"
                        draggable={false}
                        src={props.item.img}
                    />
                </Paper>
            </>
        );
    }

    return (
        <Box m="20px" height={'auto'}>
            {/* HEADER */}

            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Header
                    title="DASHBOARD"
                    subtitle={
                        userInfo
                            ? 'Welcome to your dashboard, ' +
                              (userInfo
                                  ? userInfo.roblox
                                      ? userInfo.roblox.username
                                          ? userInfo.roblox.username
                                          : 'Default'
                                      : 'Default'
                                  : 'Default') +
                              '! This is where you can view your account information, manage your services, and more.'
                            : 'Welcome to the dashboard! Please login to get started.'
                    }
                />{' '}
                {/* (👷 COMING SOON 🚧 ) */}
            </Box>

            <Card
                sx={{
                    backgroundColor:
                        theme.palette.mode === 'dark'
                            ? colors.blueAccent[400]
                            : colors.blueAccent[700],
                }}
            >
                <Box m="20px">
                    <Typography variant="h2" sx={{ fontWeight: 600 }}>
                        Public Alpha
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 400 }}>
                        This portal is still under development, please expect
                        bugs and unfinished features. If you find any bugs,
                        please report them to us on our{' '}
                        <Link
                            target="_blank"
                            href="https://discord.com/invite/xX3abhvfqU"
                            sx={{ color: 'inherit', textDecoration: 'inherit' }}
                        >
                            Discord server
                        </Link>
                        .
                    </Typography>
                </Box>
            </Card>

            <Carousel
                autoPlay={true}
                animation="slide"
                interval={5000}
                sx={{
                    mt: '25px',
                    button: {
                        mt: '30px',
                    },
                    overflow: 'hidden',
                }}
            >
                {items.map((item, i) => (
                    <Item key={i} item={item} />
                ))}
            </Carousel>
        </Box>
    );
};

export default Dashboard;
