import { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.min.css';

import { useSelector, useDispatch } from 'react-redux';
import { useGetUserQuery } from '@src/slices/authApiSlice';

import RouteTemplate from '@src/views/global/RouteTemplate';
import ErrorBoundary from '@src/global/components/ErrorBoundary';
import { ToastContainer } from 'react-toastify';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

function App(): JSX.Element {
    const dispatch = useDispatch();

    const { data, error, isLoading } = useGetUserQuery({});
    if (
        error &&
        (error as { error: string; status: string }).status === 'FETCH_ERROR'
    ) {
        console.error(
            'Could not get User Data: ',
            (error as { error: string; status: string }).error,
        );
    }

    useEffect(() => {
        if (data?.message) {
            if (data.message === 'Not Authenticated') {
                // console.log("Not Authenticated", data);
                dispatch({ type: 'auth/setCredentials', payload: null });
                // navigate('/login', { replace: true });
            }
            if (data.message === 'Authenticated') {
                if (data.user) {
                    // console.log("Authenticated", data.user);
                    dispatch({
                        type: 'auth/setCredentials',
                        payload: data.user,
                    });
                }
            }
        }
    }, [isLoading]);

    useEffect(() => {
        if ((process.env.REACT_APP_VERSION as string) === 'PROD') {
            console.clear();
        }
        console.warn(
            `🧁 Howdy 🧁\n${process.env.REACT_APP_STATUS as string} v${process.env.REACT_APP_VERSION as string}\n============`,
        );
    }, []);

    return (
        <ErrorBoundary>
            <RouteTemplate>
                <ToastContainer
                    position="bottom-right"
                    newestOnTop={false}
                    draggable
                    pauseOnHover
                    stacked
                    theme="dark"
                />
                <Outlet />
            </RouteTemplate>
        </ErrorBoundary>
    );
}

export default App;
