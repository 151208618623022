import { useEffect } from 'react';

import { GlobalContextProvider } from '@src/global/context/global';

import { ColorModeContext, useMode } from '@src/global/context/theme';

import { DataTableContextProvider } from '@src/global/context/dataTables';
import { NotificationContextProvider } from '@src/global/context/notificationProvider';

import {
    Backdrop,
    CircularProgress,
    CssBaseline,
    ThemeProvider,
} from '@mui/material';

import 'react-toastify/dist/ReactToastify.css';

import { useSelector, useDispatch } from 'react-redux';
import { setCredentials } from '@src/slices/authSlice';
import { useGetUserQuery } from '@src/slices/authApiSlice';

function RouteTemplate(props: any): JSX.Element {
    // , { loading }: any
    const dispatch = useDispatch();
    const [theme, colorMode] = useMode();
    const { data, error, isLoading } = useGetUserQuery({});

    useEffect(() => {
        if (data?.message) {
            if (data.message === 'Not Authenticated') {
                dispatch({ type: 'auth/setCredentials', payload: null });
                // console.log("Not Authenticated", data);
                // navigate('/login', { replace: true });
            }
            if (data.message === 'Authenticated') {
                if (data.user) {
                    // console.log("Authenticated", data.user);
                    dispatch({
                        type: 'auth/setCredentials',
                        payload: data.user,
                    });
                }
            }
        }
    }, [isLoading]);

    // useEffect(() => {
    //   console.log(
    //     { data },
    //     { error },
    //     { isLoading }
    //   );
    // }, [isLoading]);

    return (
        <ColorModeContext.Provider value={colorMode}>
            <GlobalContextProvider>
                <DataTableContextProvider>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <NotificationContextProvider>
                            <div className="app">
                                <Backdrop
                                    sx={{
                                        color: '#fff',
                                        zIndex: (currTheme) =>
                                            currTheme.zIndex.drawer + 1,
                                        backgroundColor: 'rgba(20, 20, 20, 1)',
                                    }}
                                    open={isLoading || false}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>

                                {props.children}
                            </div>
                        </NotificationContextProvider>
                    </ThemeProvider>
                </DataTableContextProvider>
            </GlobalContextProvider>
        </ColorModeContext.Provider>
    );
}

export default RouteTemplate;
