import { useTheme } from '@mui/material';
import { getColors } from '@src/global/context/theme';
import _ from 'lodash';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

interface ProtectedNavigateProps {
    roles?: Array<{ name: string; scope: string }>;
    permissions?: Array<{ permission: string; scope: string }> | any | string;
    redirect?: string;
    children?: JSX.Element;
}

const ProtectedNavigate = ({
    roles = [] as any,
    permissions = [],
    redirect = '/',
    children,
}: ProtectedNavigateProps): JSX.Element => {
    const theme = useTheme();
    const colors = getColors(theme.palette.mode);

    const { userInfo } = useSelector((state: any) => state.auth, shallowEqual);

    if (
        userInfo?.roles?.some((userRole: any) =>
            userRole
                ? roles?.some((_role: any) => {
                      return (
                          _role ??
                          (userRole.role === _role.name &&
                              userRole.scope === _role.scope)
                      );
                  })
                : false,
        ) ||
        userInfo?.permissions?.some((userPermission: any) =>
            userPermission
                ? permissions?.some((_permission: any) => {
                      return (
                          userPermission.permission === _permission.permission
                      );
                  })
                : false,
        )
    ) {
        return children ?? <></>;
    } else {
        console.log('Redirecting to', redirect);
        return <Navigate to={redirect} />;
    }
};

export default ProtectedNavigate;
