import { configureStore } from '@reduxjs/toolkit';
import authReducer from '@src/slices/authSlice';
import dataTableReducer from '@src/slices/dataTableSlice';
import rolesReducer from '@src/slices/rolesSlice';

import { apiSlice } from '@src/slices/apiSlice';

const store = configureStore({
    reducer: {
        auth: authReducer,
        dataTable: dataTableReducer,
        [apiSlice.reducerPath]: apiSlice.reducer,
        roles: rolesReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: !!(
        (process.env.REACT_APP_STORE as string) !== 'PRODUCTION' ||
        (process.env.REACT_APP_STORE as string) !== 'PROD' ||
        (process.env.REACT_APP_STORE as string) !== 'PRD'
    ),
});

export default store;
