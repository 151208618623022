import react, { useContext, useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Sidebar, SubMenu, Menu, MenuItem } from 'react-pro-sidebar';
import {
    Avatar,
    Box,
    Collapse,
    Divider,
    IconButton,
    Paper,
    Typography,
    useTheme,
    Link as MuiLink,
} from '@mui/material';
import { getColors } from '@src/global/context/theme';
import { SidebarContext } from '@src/global/context/sidebar';
import { GlobalContext } from '@src/global/context/global';

import { useLogoutMutation } from '@src/slices/authApiSlice';
import { logout } from '@src/slices/authSlice';

import {
    HomeOutlined as HomeOutlinedIcon,
    MenuOutlined as MenuOutlinedIcon,
    Memory as MemoryIcon,
    DownloadOutlined as DownloadOutlinedIcon,
    Folder as FolderIcon,
    FolderOpen as FolderOpenIcon,
    VisibilityOutlined as VisibilityOutlinedIcon,
    QuestionMarkOutlined,
    ArticleOutlined as ArticleOutlinedIcon,
    DeveloperMode as DeveloperModeIcon,
    Poll as PollIcon,
    Ballot as BallotIcon,
    HowToReg as HowToRegIcon,
    Security as SecurityIcon,
    AdminPanelSettings as AdminPanelSettingsIcon,
    Link as LinkIcon,
    Gavel as GavelIcon,
    Group as GroupIcon,
    RecentActors as UsersIcon,
    Map as MapIcon,
    PersonOutlined,
    SettingsOutlined,
    Logout,
} from '@mui/icons-material';

// import RecentActorsIcon from '@mui/icons-material/RecentActors';

import axios from 'axios';
import { toast } from 'react-toastify';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';

interface ItemProps {
    title: string;
    id: string;
    to: string;
    icon: JSX.Element;
    selected: string;
    setSelected: (id: string) => void;
    disabled?: boolean;
}

const Item = ({
    title,
    id,
    to,
    icon,
    selected,
    setSelected,
    disabled = false,
}: ItemProps): JSX.Element => {
    const theme = useTheme();
    const colors = getColors(theme.palette.mode);

    return (
        <MenuItem
            active={selected === id}
            style={{
                color: disabled ? colors.grey[400] : colors.primary[100],
            }}
            onClick={() => {
                setSelected(id);
            }}
            icon={icon}
            disabled={disabled}
            component={to ? <Link to={to} /> : ''}
        >
            <>
                <Typography variant="h3">{title}</Typography>
            </>
        </MenuItem>
    );
};

interface SidebarItemProps {
    title: string;
    to: string;
    icon: JSX.Element;
    selected: string;
    setSelected: (id: string) => void;
    disabled?: boolean;
    children?: JSX.Element;
    onClick?: () => void;
}

const SidebarItem = ({
    title,
    to,
    icon,
    selected,
    setSelected,
    disabled = false,
    children = <></>,
    onClick = () => {
        setSelected(title);
    },
}: SidebarItemProps): JSX.Element => {
    const theme = useTheme();
    const colors = getColors(theme.palette.mode);

    return (
        <MenuItem
            active={selected === title}
            style={{
                color: disabled ? colors.grey[100] : colors.blueAccent[200],
            }}
            onClick={onClick}
            icon={icon}
            disabled={disabled}
            component={to ? <Link to={to} /> : ''}
        >
            <>{children}</>
        </MenuItem>
    );
};

interface ProtectedItemProps {
    disabled?: boolean;
    children?: JSX.Element;
    roles?: [{ name: string; scope: string }];
    permissions?: [{ permission: string; scope: string }] | any | string;
}

const ProtectedItem = ({
    disabled = false,
    children = <></>,
    roles,
    permissions,
}: ProtectedItemProps): JSX.Element => {
    const { userInfo } = useSelector((state: any) => state.auth, shallowEqual);

    // userInfo?.roles && (userInfo.roles as string[]);

    return userInfo?.roles?.some((userRole: any) =>
        userRole
            ? roles?.some((_role: any) => {
                  return (
                      userRole.role === _role.name &&
                      userRole.scope === _role.scope
                  );
              })
            : false,
    ) ||
        userInfo?.permissions?.some((userPermission: any) =>
            userPermission
                ? permissions?.some((_permission: any) => {
                      return (
                          userPermission.permission ===
                              _permission.permission &&
                          userPermission.scope === _permission.scope
                      );
                  })
                : false,
        ) ? (
        <>{children}</>
    ) : (
        <></>
    );
};

const mySidebar: React.FC = () => {
    const theme = useTheme();
    const colors = getColors(theme.palette.mode);
    const [selected, setSelected] = useContext(SidebarContext);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userInfo } = useSelector((state: any) => state.auth, shallowEqual);

    const { sidebarCollapsed, toggleSidebar } = useContext(GlobalContext);

    const [logoutApiCall] = useLogoutMutation();

    const logoutHandler: any = async () => {
        try {
            const res = await logoutApiCall({}).unwrap();
            if (res && res.message !== 'Successfully Logged Out') {
                toast.error('An error occured while logging out');
                return;
            }
            dispatch(logout());
            navigate('/');
            toast.success('Successfully logged out');
        } catch (e) {
            toast.error('Unhandeled error occured while logging out');
            console.log(e);
        }
    };

    return (
        <>
            <Box
                sx={{
                    '& .ps-sidebar-root': {
                        height:
                            userInfo && !sidebarCollapsed ? '85vh' : '100vh',
                        border: 'none',
                        overflow: 'hidden',
                    },
                    '& .ps-sidebar-container': {
                        background:
                            theme.palette.mode === 'dark'
                                ? `${colors.primary[400]}`
                                : '#fcfcfc',
                        width: '100%',
                    },

                    '& .ps-menu-button': {
                        '& .ps-menu-label': {
                            fontFamily: ['Source Sans Pro', 'sans-serif'].join(
                                ',',
                            ),
                            fontSize: '32',
                        },
                        '&:hover': {
                            backgroundColor:
                                theme.palette.mode === 'dark'
                                    ? `${colors.primary[500]} !important`
                                    : '#fcfcfc !important',
                            color: `${colors.blueAccent[300]} !important`,
                        },
                        '& .ps-active': {
                            color: `${colors.blueAccent[300]} !important`,
                        },
                    },
                }}
            >
                <Sidebar collapsed={sidebarCollapsed}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={'center'}
                        overflow={'hidden'}
                        paddingTop={'1rem'}
                        sx={{
                            backgroundColor:
                                theme.palette.mode === 'dark'
                                    ? colors.primary[500]
                                    : '#fcfcfc',
                        }}
                    >
                        <img
                            alt="nusa_logo"
                            width="40px"
                            height="40px"
                            src={
                                window.location.pathname.includes('moderation')
                                    ? '/assets/moderation_services.png'
                                    : '/assets/NUSALOGO.png'
                            }
                            draggable="false"
                            style={{
                                borderRadius: '50%',
                                backgroundColor: colors.primary[300],
                                border: `1px solid ${colors.primary[200]}`,
                            }}
                            loading="lazy"
                        />
                        {!sidebarCollapsed && (
                            <Typography
                                variant="h2"
                                fontWeight={'semibold'}
                                color={colors.grey[100]}
                                sx={{ paddingLeft: '0.4rem' }}
                            >
                                {/* nUSA Cyber Services */}
                                {window.location.pathname.includes('moderation')
                                    ? 'Moderation'
                                    : 'CyberServices'}
                            </Typography>
                        )}
                    </Box>
                    <Menu
                        menuItemStyles={{
                            subMenuContent: {
                                backgroundColor: colors.primary[400],
                                paddingLeft: '5px !important',
                            },
                        }}
                    >
                        <Box
                            display="flex"
                            flexDirection={'column'}
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                width="100%"
                                bgcolor={
                                    theme.palette.mode === 'dark'
                                        ? colors.primary[500]
                                        : '#fcfcfc'
                                }
                                sx={
                                    !sidebarCollapsed
                                        ? {
                                              '&::before': {
                                                  content: "''",
                                                  position: 'absolute',
                                                  backgroundColor:
                                                      'transparent',
                                                  marginTop: 'calc(28% + 1rem)',
                                                  left: '0',
                                                  height: '50px',
                                                  width: '25px',
                                                  // borderTopLeftRadius: "60%",
                                                  boxShadow:
                                                      '0 -25px 0 0 ' +
                                                      (theme.palette.mode ===
                                                      'dark'
                                                          ? colors.primary[500]
                                                          : '#fcfcfc') /* This is where the magic happens! */,
                                              },
                                              '&::after': {
                                                  content: "''",
                                                  position: 'absolute',
                                                  backgroundColor:
                                                      'transparent',
                                                  marginTop: 'calc(28% + 1rem)',
                                                  right: '0',
                                                  height: '50px',
                                                  width: '25px',
                                                  borderTopRightRadius: '60%',
                                                  boxShadow:
                                                      '0 -25px 0 0 ' +
                                                      (theme.palette.mode ===
                                                      'dark'
                                                          ? colors.primary[500]
                                                          : '#fcfcfc') /* This is where the magic happens! */,
                                              },
                                          }
                                        : {}
                                }
                            >
                                <IconButton onClick={() => toggleSidebar()}>
                                    <MenuOutlinedIcon />
                                </IconButton>
                            </Box>
                        </Box>

                        {/* <Divider
                            sx={{
                                marginTop: sidebarCollapsed ? '0rem' : '1rem',
                                marginBottom: '1rem',
                                opacity: 0.6,
                            }}
                        /> */}

                        <Box
                            marginTop={sidebarCollapsed ? '0rem' : '1rem'}
                            paddingLeft={sidebarCollapsed ? undefined : '4%'}
                            paddingRight={sidebarCollapsed ? undefined : '4%'}
                        >
                            <Item
                                title="Home"
                                id="Home"
                                to="/"
                                icon={<HomeOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Ban Database"
                                id="Ban Database"
                                to="/bans"
                                icon={<GavelIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Game Map"
                                id="Game Map"
                                to="/game-map"
                                icon={<MapIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                disabled
                            />

                            <ProtectedItem
                                roles={[
                                    {
                                        name: 'Group Management',
                                        scope: 'Global',
                                    },
                                ]}
                            >
                                <>
                                    <Divider
                                        sx={{
                                            marginTop: sidebarCollapsed
                                                ? '0rem'
                                                : '1rem',
                                            marginBottom: '1rem',
                                        }}
                                        light
                                    />
                                    <SubMenu
                                        label="FEC Elections"
                                        icon={<PollIcon />}
                                        style={{
                                            color: colors.blueAccent[200],
                                        }}
                                        rootStyles={{
                                            backgroundColor:
                                                colors.primary[400],
                                            '& span': {
                                                fontFamily: [
                                                    'Source Sans Pro',
                                                    'sans-serif',
                                                ].join(','),
                                                fontSize: '32',
                                            },
                                        }}
                                    >
                                        <Item
                                            title="Ballot Controls"
                                            id="Ballot Controls"
                                            to="/elections/ballots"
                                            icon={<BallotIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        <Item
                                            title="Filtering Panel"
                                            id="Filtering Panel"
                                            to="/elections/filtering"
                                            icon={<HowToRegIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                    </SubMenu>
                                </>
                            </ProtectedItem>

                            {/* {userInfo?.groups &&
                            (userInfo.groups as string[]).includes('FEC') ? (
                                <>
                                    <Divider
                                        sx={{
                                            marginTop: sidebarCollapsed
                                                ? '0rem'
                                                : '1rem',
                                            marginBottom: '1rem',
                                        }}
                                        light
                                    />
                                    <SubMenu
                                        label="FEC Elections"
                                        icon={<PollIcon />}
                                        style={{
                                            color: colors.blueAccent[200],
                                        }}
                                        rootStyles={{
                                            backgroundColor:
                                                colors.primary[400],
                                            '& span': {
                                                fontFamily: [
                                                    'Source Sans Pro',
                                                    'sans-serif',
                                                ].join(','),
                                                fontSize: '32',
                                            },
                                        }}
                                    >
                                        <Item
                                            title="Ballot Controls"
                                            id="Ballot Controls"
                                            to="/elections/ballots"
                                            icon={<BallotIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        <Item
                                            title="Filtering Panel"
                                            id="Filtering Panel"
                                            to="/elections/filtering"
                                            icon={<HowToRegIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                    </SubMenu>
                                </>
                            ) : (
                                <></>
                            )} */}

                            <ProtectedItem
                                roles={[
                                    { name: 'Moderation', scope: 'Moderation' },
                                ]}
                                permissions={[
                                    {
                                        permission: 'readBans',
                                        scope: 'Moderation',
                                    },
                                    {
                                        permission: 'readBans',
                                        scope: 'Global',
                                    },
                                ]}
                            >
                                <>
                                    <Divider
                                        sx={{
                                            marginTop: sidebarCollapsed
                                                ? '0rem'
                                                : '1rem',
                                            marginBottom: '1rem',
                                        }}
                                        light
                                    />
                                    <SubMenu
                                        label="Moderation"
                                        icon={<SecurityIcon />}
                                        style={{
                                            color: colors.blueAccent[200],
                                        }}
                                        rootStyles={{
                                            backgroundColor:
                                                colors.primary[400],
                                        }}
                                    >
                                        <Item
                                            title="Staffing"
                                            id="Moderation Staffing"
                                            to="/moderation/staff"
                                            icon={<GroupIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        <ProtectedItem
                                            roles={[
                                                {
                                                    name: 'Moderation',
                                                    scope: 'Moderation',
                                                },
                                            ]}
                                            permissions={[
                                                {
                                                    permission: 'readBans',
                                                    scope: 'Moderation',
                                                },
                                                {
                                                    permission: 'readBans',
                                                    scope: 'Global',
                                                },
                                            ]}
                                        >
                                            <Item
                                                title="Bans"
                                                id="Bans"
                                                to="/moderation/bans"
                                                icon={<GavelIcon />}
                                                selected={selected}
                                                setSelected={setSelected}
                                            />
                                        </ProtectedItem>
                                    </SubMenu>
                                </>
                            </ProtectedItem>

                            {/* {userInfo?.groups &&
                            (userInfo.groups as string[]).includes(
                                'Moderation',
                            ) ? (
                                <>
                                    <Divider
                                        sx={{
                                            marginTop: sidebarCollapsed
                                                ? '0rem'
                                                : '1rem',
                                            marginBottom: '1rem',
                                        }}
                                        light
                                    />
                                    <SubMenu
                                        label="Moderation"
                                        icon={<SecurityIcon />}
                                        style={{
                                            color: colors.blueAccent[200],
                                        }}
                                        rootStyles={{
                                            backgroundColor:
                                                colors.primary[400],
                                        }}
                                    >
                                        <Item
                                            title="Staffing"
                                            id="Moderation Staffing"
                                            to="/moderation/staff"
                                            icon={<GroupIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        {userInfo?.groups &&
                                        (userInfo.groups as string[]).includes(
                                            'Group Management',
                                        ) ? (
                                            <Item
                                                title="Bans"
                                                id="Bans"
                                                to="/moderation/bans"
                                                icon={<GavelIcon />}
                                                selected={selected}
                                                setSelected={setSelected}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </SubMenu>
                                </>
                            ) : (
                                <></>
                            )} */}

                            <ProtectedItem
                                permissions={[
                                    {
                                        permission: 'readAdministration',
                                        scope: 'Global',
                                    },
                                ]}
                            >
                                <>
                                    <Divider
                                        sx={{
                                            marginTop: sidebarCollapsed
                                                ? '0rem'
                                                : '1rem',
                                            marginBottom: '1rem',
                                        }}
                                        light
                                    />
                                    <SubMenu
                                        label="Administration"
                                        icon={<AdminPanelSettingsIcon />}
                                        style={{
                                            color: colors.blueAccent[200],
                                        }}
                                        rootStyles={{
                                            backgroundColor:
                                                colors.primary[400],
                                        }}
                                    >
                                        <Item
                                            title="Staffing"
                                            id="Administration Staffing"
                                            to="/admin/staff"
                                            icon={<GroupIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        <ProtectedItem
                                            permissions={[
                                                {
                                                    permission: 'readAllUsers',
                                                    scope: 'Global',
                                                },
                                            ]}
                                        >
                                            <Item
                                                title="All Users"
                                                id="All Users"
                                                to="/admin/users"
                                                icon={<UsersIcon />}
                                                selected={selected}
                                                setSelected={setSelected}
                                            />
                                        </ProtectedItem>
                                        <ProtectedItem
                                            permissions={[
                                                {
                                                    permission:
                                                        'readAllBindings',
                                                    scope: 'Global',
                                                },
                                            ]}
                                        >
                                            <Item
                                                title="Bindings"
                                                id="Bindings"
                                                to="/admin/bindings"
                                                icon={<LinkIcon />}
                                                selected={selected}
                                                setSelected={setSelected}
                                            />
                                        </ProtectedItem>
                                        <ProtectedItem
                                            permissions={[
                                                {
                                                    permission: 'readAllLogs',
                                                    scope: 'Global',
                                                },
                                            ]}
                                        >
                                            <Item
                                                title="Logs"
                                                id="Logs"
                                                to="/admin/logs"
                                                icon={
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="icon icon-tabler icon-tabler-file-delta"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="2"
                                                        stroke={
                                                            selected === 'Logs'
                                                                ? colors
                                                                      .blueAccent[200]
                                                                : colors
                                                                      .primary[100]
                                                        }
                                                        fill="none"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    >
                                                        {' '}
                                                        <path
                                                            stroke="none"
                                                            d="M0 0h24v24H0z"
                                                            fill="none"
                                                        />{' '}
                                                        <path d="M14 3v4a1 1 0 0 0 1 1h4" />{' '}
                                                        <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />{' '}
                                                        <path d="M9 17h6l-3 -6z" />{' '}
                                                    </svg>
                                                }
                                                selected={selected}
                                                setSelected={setSelected}
                                            />
                                        </ProtectedItem>
                                    </SubMenu>
                                </>
                            </ProtectedItem>

                            {/* {userInfo?.groups &&
                            (userInfo.groups as string[]).includes(
                                'API_Docs',
                            ) ? (
                                <>
                                    <Divider
                                        sx={{
                                            marginTop: sidebarCollapsed
                                                ? '0rem'
                                                : '1rem',
                                            marginBottom: '1rem',
                                        }}
                                        light
                                    />
                                    <SubMenu
                                        label="Administration"
                                        icon={<AdminPanelSettingsIcon />}
                                        style={{
                                            color: colors.blueAccent[200],
                                        }}
                                        rootStyles={{
                                            backgroundColor:
                                                colors.primary[400],
                                        }}
                                    >
                                        <Item
                                            title="Staffing"
                                            id="Administration Staffing"
                                            to="/admin/staff"
                                            icon={<GroupIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        <Item
                                            title="Bindings"
                                            id="Bindings"
                                            to="/admin/bindings"
                                            icon={<LinkIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                    </SubMenu>
                                </>
                            ) : (
                                <></>
                            )} */}

                            <ProtectedItem permissions={['readAPIDocs']}>
                                <>
                                    <Divider
                                        sx={{
                                            marginTop: sidebarCollapsed
                                                ? '0rem'
                                                : '1rem',
                                            marginBottom: '1rem',
                                        }}
                                        light
                                    />
                                    <SubMenu
                                        label="Documentation"
                                        id="Documentation"
                                        icon={<SecurityIcon />}
                                        style={{
                                            color: colors.blueAccent[200],
                                        }}
                                        rootStyles={{
                                            backgroundColor:
                                                colors.primary[400],
                                        }}
                                    >
                                        <ProtectedItem
                                            permissions={['readAPIDocs']}
                                        >
                                            <Item
                                                title="API Docs"
                                                id="API Docs"
                                                to="/api"
                                                icon={<DeveloperModeIcon />}
                                                selected={selected}
                                                setSelected={setSelected}
                                            />
                                        </ProtectedItem>
                                    </SubMenu>
                                </>
                            </ProtectedItem>

                            {/* {userInfo?.groups &&
                            (userInfo.groups as string[]).includes(
                                'API_Docs',
                            ) ? (
                                <>
                                    <Divider
                                        sx={{
                                            marginTop: sidebarCollapsed
                                                ? '0rem'
                                                : '1rem',
                                            marginBottom: '1rem',
                                        }}
                                        light
                                    />
                                    <SubMenu
                                        label="Documentation"
                                        id="Documentation"
                                        icon={<SecurityIcon />}
                                        style={{
                                            color: colors.blueAccent[200],
                                        }}
                                        rootStyles={{
                                            backgroundColor:
                                                colors.primary[400],
                                        }}
                                    >
                                        {userInfo?.groups &&
                                        (userInfo.groups as string[]).includes(
                                            'API_Docs',
                                        ) ? (
                                            <Item
                                                title="API Docs"
                                                id="API Docs"
                                                to="/api"
                                                icon={<DeveloperModeIcon />}
                                                selected={selected}
                                                setSelected={setSelected}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </SubMenu>
                                </>
                            ) : (
                                <></>
                            )} */}

                            {/* <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
              >
                Help
              </Typography>
              <Item
                title="FAQ"
                to="/faq"
                icon={<QuestionMarkOutlined />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="API Documentation"
                to="/api"
                icon={<ArticleOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              /> */}
                        </Box>
                    </Menu>
                </Sidebar>

                {userInfo && !sidebarCollapsed && (
                    <Paper
                        elevation={6}
                        sx={{
                            width: '100%',
                            p: '5px',
                            borderRadius: '4px',
                            display: 'flex',
                            gap: '10px',
                            alignItems: 'center',
                            // justifyContent: "center",
                            mt: '7px',
                            mb: '7px',
                            backgroundColor:
                                theme.palette.mode === 'dark'
                                    ? colors.primary[700]
                                    : '#fcfcfc',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    p: '10px',
                                    borderRadius: '4px',
                                    display: 'flex',
                                    gap: '10px',
                                    // alignItems: "center",
                                    // justifyContent: "center",
                                }}
                            >
                                {/* <Avatar src={(userInfo ? userInfo.avatar ? "https://cdn.discordapp.com/avatars/" + userInfo.id + "/" + userInfo.avatar : "" : "")} variant="rounded" sx={{ minWidth: 50, minHeight: 50, border: `3px solid ${colors.primary[700]}`, backgroundColor: colors.primary[700] }}/> */}
                                <MuiLink
                                    target="_blank"
                                    rel="noopener"
                                    href={
                                        userInfo
                                            ? userInfo.roblox
                                                ? `https://www.roblox.com/users/${userInfo.roblox.id}/profile`
                                                : ''
                                            : ''
                                    }
                                >
                                    <Avatar
                                        src={
                                            userInfo
                                                ? userInfo.roblox
                                                    ? userInfo.roblox.thumbnail
                                                        ? userInfo.roblox
                                                              .thumbnail
                                                        : ''
                                                    : ''
                                                : ''
                                        }
                                        variant="rounded"
                                        sx={{
                                            minWidth: 50,
                                            minHeight: 50,
                                            border: `3px solid ${colors.primary[700]}`,
                                            backgroundColor:
                                                colors.primary[700],
                                        }}
                                    />
                                </MuiLink>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        gap: '5px',
                                    }}
                                >
                                    <MuiLink
                                        target="_blank"
                                        rel="noopener"
                                        href={
                                            userInfo
                                                ? userInfo.roblox
                                                    ? userInfo.roblox.id
                                                        ? `https://www.roblox.com/users/${userInfo.roblox.id}/profile`
                                                        : ''
                                                    : ''
                                                : ''
                                        }
                                    >
                                        <Typography
                                            variant="h3"
                                            sx={{
                                                fontWeight: 'bold',
                                                color: colors.primary[100],
                                            }}
                                        >
                                            {userInfo
                                                ? userInfo.roblox
                                                    ? userInfo.roblox.username
                                                        ? userInfo.roblox
                                                              .username
                                                        : 'Default Name'
                                                    : 'Default Name'
                                                : 'Default Name'}
                                        </Typography>
                                    </MuiLink>
                                    <Typography
                                        variant="h5"
                                        sx={{ color: colors.blueAccent[200] }}
                                    >
                                        {userInfo
                                            ? userInfo.roblox
                                                ? userInfo.roblox.id
                                                    ? userInfo.roblox.id
                                                    : 'Default ID'
                                                : 'Default ID'
                                            : 'Default ID'}
                                    </Typography>
                                    {/* <Chip label={`${row.username} (${row.user_id})`} variant="filled" sx={{ backgroundColor: colors.primary[700] }}/> */}
                                    {/* <Chip label={row.user_id} variant="filled" sx={{ backgroundColor: colors.primary[700] }}/> */}
                                </Box>
                            </Box>
                            <Box
                                display="grid"
                                gridTemplateColumns="repeat(6, 1fr)"
                                // gridAutoRows="140px"
                                gap="5px"
                            >
                                <IconButton sx={{ gridColumn: 1 }}>
                                    <SettingsOutlined />
                                </IconButton>

                                <IconButton onClick={() => {}}>
                                    <PersonOutlined />
                                </IconButton>

                                <IconButton
                                    onClick={logoutHandler}
                                    sx={{
                                        gridColumnEnd: -1,
                                        transition:
                                            'background 0.2s, color 0.2s',
                                        '&:hover': {
                                            color: colors.redAccent[400],
                                        },
                                    }}
                                >
                                    <Logout />
                                </IconButton>
                            </Box>
                        </Box>
                    </Paper>
                )}
            </Box>
        </>
    );
};

export default mySidebar;
