import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URI as string,
});

export const apiSlice = createApi({
    baseQuery,
    tagTypes: ['User', 'Metrics'],
    endpoints: (builder) => ({}),
});
