import { createContext, useState } from 'react';

import axios from 'axios';
import { toast } from 'react-toastify';

// import { useGetTableDataQuery } from "@src/slices/dataTableApiSlice";

export const DataTableContext = createContext({} as any);

export const DataTableContextProvider = ({ children }: any): JSX.Element => {
    const [ISDTableDataISD, setISDTableData] = useState([{}]);
    const [ISDATTableDataISD, setISDATableData] = useState([]);
    const [ISDTTableDataISD, setISDTTableData] = useState([]);

    // let data = useGetTableDataQuery({});

    const refresh = async (
        { doToast = true } = { doToast: false },
    ): Promise<void> => {
        await axios
            .get(
                (process.env.REACT_APP_API_URI as string) +
                    `/storage/${document.location.pathname.split('/')[2]}`,
                { withCredentials: true },
            )
            .then(
                (res) => {
                    // console.log("Refresh: ", data);
                    if (res.status === 200) {
                        if (doToast) {
                            toast(
                                (t) => (
                                    <span
                                        onClick={() => {
                                            toast.dismiss(t.toastProps.toastId);
                                        }}
                                    >
                                        Successfully reloaded table.
                                    </span>
                                ),
                                { icon: <>✅</>, type: 'success' },
                            );
                        }

                        switch (document.location.pathname.split('/')[2]) {
                            case 'isd':
                                setISDTableData(
                                    res.data.data as React.SetStateAction<
                                        any[]
                                    >,
                                );
                                break;
                            case 'isdat':
                                setISDATableData(
                                    res.data.data as React.SetStateAction<
                                        never[]
                                    >,
                                );
                                break;
                            case 'isdt':
                                setISDTTableData(
                                    res.data.data as React.SetStateAction<
                                        never[]
                                    >,
                                );
                                break;
                        }
                    } else {
                        toast(
                            (t) => (
                                <span
                                    onClick={() => {
                                        toast.dismiss(t.toastProps.toastId);
                                    }}
                                >
                                    Failed to reload table.
                                </span>
                            ),
                            { icon: <>❌</>, type: 'error' },
                        );
                    }
                },
                () => {
                    toast(
                        (t) => (
                            <span
                                onClick={() => {
                                    toast.dismiss(t.toastProps.toastId);
                                }}
                            >
                                Could not connect to API.
                            </span>
                        ),
                        { icon: <>❌</>, type: 'error' },
                    );
                },
            );
    };

    return (
        <DataTableContext.Provider
            value={[
                refresh,
                ISDTableDataISD,
                ISDATTableDataISD,
                ISDTTableDataISD,
            ]}
        >
            {children}
        </DataTableContext.Provider>
    );
};
