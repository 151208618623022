import React from 'react';
import { Route } from 'react-router';
import ReactDOM from 'react-dom/client';
import '@src/index.css';
import App from '@src/App';

import API from '@src/views/api/api';
import Dashboard from '@src/views/dashboard';
import Error404 from '@src/views/errors/error404';
import FAQ from '@src/views/faq/faq';
import Bans from '@src/views/public/bans';
import TOS from '@src/views/tos';
import PrivacyPolicy from '@src/views/privacy';

import Filtering from '@src/views/elections/Filtering';
import BallotControls from '@src/views/elections/BallotControls';

import ModerationStaff from '@src/views/moderation/staff';
import ModerationBans from '@src/views/moderation/bans';

import AdminBindings from '@src/views/admin/bindings';
import AdminStaff from '@src/views/admin/staff';
import AdminUsers from '@src/views/admin/users';
import AdminLogs from '@src/views/admin/logs';

import Login from '@src/views/login/login';
import DashboardRoute from '@src/global/components/DashboardRoute';
import ProtectedNavigate from '@src/global/components/ProtectedNavigate';

import Index from '@src/views/public';

import {
    Navigate,
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
} from 'react-router-dom';

import store from '@src/store';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

const newRoutes = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<App />}>
            <Route path="/terms-of-service" element={<TOS />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="" element={<DashboardRoute />}>
                <Route index={true} path="/" element={<Index />} />
                <Route path="/bans" element={<Bans />} />
                <Route path="/game-map" element={<Bans />} />
                <Route path="/dashboard" element={<Dashboard />} />

                <Route
                    path="/elections/ballots"
                    element={
                        <ProtectedNavigate
                            roles={[
                                { name: 'Group Management', scope: 'Global' },
                            ]}
                        >
                            <BallotControls />
                        </ProtectedNavigate>
                    }
                />
                <Route
                    path="/elections/filtering"
                    element={
                        <ProtectedNavigate
                            roles={[
                                { name: 'Group Management', scope: 'Global' },
                            ]}
                        >
                            <Filtering />
                        </ProtectedNavigate>
                    }
                />

                <Route
                    path="/moderation/staff"
                    element={
                        <ProtectedNavigate
                            roles={[
                                { name: 'Group Management', scope: 'Global' },
                            ]}
                        >
                            <ModerationStaff />
                        </ProtectedNavigate>
                    }
                />

                <Route
                    path="/moderation/bans"
                    element={
                        <ProtectedNavigate
                            roles={[{ name: 'Moderation', scope: 'Global' }]}
                        >
                            <ModerationBans />
                        </ProtectedNavigate>
                    }
                />

                <Route
                    path="/admin/bindings"
                    element={
                        <ProtectedNavigate
                            roles={[
                                { name: 'Group Management', scope: 'Global' },
                                { name: 'Developer', scope: 'Global' },
                            ]}
                        >
                            <AdminBindings />
                        </ProtectedNavigate>
                    }
                />

                <Route
                    path="/admin/users"
                    element={
                        <ProtectedNavigate
                            roles={[
                                { name: 'Group Management', scope: 'Global' },
                                { name: 'Developer', scope: 'Global' },
                            ]}
                        >
                            <AdminUsers />
                        </ProtectedNavigate>
                    }
                />
                <Route
                    path="/admin/logs"
                    element={
                        <ProtectedNavigate
                            roles={[
                                { name: 'Group Management', scope: 'Global' },
                                { name: 'Developer', scope: 'Global' },
                            ]}
                        >
                            <AdminLogs />
                        </ProtectedNavigate>
                    }
                />

                <Route
                    path="/admin/staff"
                    element={
                        <ProtectedNavigate
                            roles={[
                                { name: 'Group Management', scope: 'Global' },
                                { name: 'Developer', scope: 'Global' },
                            ]}
                        >
                            <AdminStaff />
                        </ProtectedNavigate>
                    }
                />

                <Route path="/faq" element={<FAQ />} />
                <Route
                    path="/api"
                    element={
                        <ProtectedNavigate
                            roles={[{ name: 'Developer', scope: 'Global' }]}
                        >
                            <API />
                        </ProtectedNavigate>
                    }
                />

                <Route path="/*" element={<Error404 />} />
            </Route>

            {/* <Route path="/login" element={<Login />} /> */}
            <Route path="/*" element={<Navigate to="/" />} />
        </Route>,
    ),
);

root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <RouterProvider router={newRoutes} />
    </Provider>,
    // </React.StrictMode>
);
