import { Box, useTheme } from '@mui/material';
import Header from '@src/global/components/Header';
import { getColors } from '@src/global/context/theme';

import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import '@src/swagger-dark.css';

const API = (): JSX.Element => {
    const theme = useTheme();
    const colors = getColors(theme.palette.mode);

    return (
        <>
            <Box m="20px">
                <Header
                    title="API Documentation"
                    subtitle="General API Endpoints"
                />

                <SwaggerUI url="/nusa.json" />
            </Box>
        </>
    );
};

export default API;
