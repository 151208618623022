import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tableData:
        localStorage.getItem('tableData') == null
            ? JSON.parse(localStorage.getItem('tableData') as string)
            : {},
    activeBanData:
        localStorage.getItem('activeBanData') == null
            ? JSON.parse(localStorage.getItem('activeBanData') as string)
            : {},
    inactiveBanData:
        localStorage.getItem('inactiveBanData') == null
            ? JSON.parse(localStorage.getItem('inactiveBanData') as string)
            : {},
    staffData:
        localStorage.getItem('staffData') == null
            ? JSON.parse(localStorage.getItem('staffData') as string)
            : {},
};

const dataTableSlice = createSlice({
    name: 'dataTable',
    initialState,
    reducers: {
        setTableData: (state, action) => {
            state.tableData[action.payload.key] = action.payload.data;
            localStorage.setItem('tableData', JSON.stringify(state.tableData));
        },
        setBanData: (state, action) => {
            // state.banData = action.payload.data;
            // console.log(action.payload.showActiveBans);
            // localStorage.setItem('banData', JSON.stringify(state.banData));
            if (action.payload.showActiveBans) {
                state.activeBanData = action.payload.data;
                localStorage.setItem(
                    'activeBanData',
                    JSON.stringify(state.activeBanData),
                );
            } else {
                state.inactiveBanData = action.payload.data;
                localStorage.setItem(
                    'inactiveBanData',
                    JSON.stringify(state.inactiveBanData),
                );
            }
        },
        setStaffData: (state, action) => {
            state.staffData = action.payload.data;
            localStorage.setItem('staffData', JSON.stringify(state.staffData));
        },
        deleteBans: (state, action) => {
            if (action.payload.showActiveBans) {
                state.activeBanData.bans = state.activeBanData.bans.filter(
                    (ban: any) => !action.payload.data.includes(ban._id),
                );
                localStorage.setItem(
                    'activeBanData',
                    JSON.stringify(state.activeBanData),
                );
            } else {
                state.inactiveBanData.bans = state.inactiveBanData.bans.filter(
                    (ban: any) => !action.payload.data.includes(ban._id),
                );
                localStorage.setItem(
                    'inactiveBanData',
                    JSON.stringify(state.inactiveBanData),
                );
            }
        },
    },
});

export const { setTableData, setBanData, setStaffData, deleteBans } =
    dataTableSlice.actions;

export default dataTableSlice.reducer;
