import { createContext, useState, useMemo } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export const ThemeContext = createContext({} as any);

// color design tokens
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getColors = (mode: string) => ({
    ...(mode === 'dark' ? darkColors : lightColors),
});

const darkColors = {
    grey: {
        100: '#e0e0e0',
        200: '#c2c2c2',
        300: '#a3a3a3',
        400: '#858585',
        500: '#666666',
        600: '#525252',
        700: '#3d3d3d',
        800: '#292929',
        900: '#141414',
    },
    // primary: {
    //     100: "#d0d1d5",
    //     200: "#a1a4ab",
    //     300: "#727681",
    //     400: "#434957",
    //     500: "#141b2d",
    //     600: "#101624",
    //     700: "#0c101b",
    //     800: "#080b12",
    //     900: "#040509"
    // },
    primary: {
        100: '#d2d2d2',
        200: '#a5a5a5',
        300: '#797979',
        // 400: "#4c4c4c",
        400: '#262626',
        500: '#1f1f1f',
        600: '#191919',
        700: '#131313',
        800: '#0c0c0c',
        900: '#060606',
    },
    greenAccent: {
        100: '#dbf5ee',
        200: '#b7ebde',
        300: '#94e2cd',
        400: '#70d8bd',
        500: '#4cceac',
        600: '#3da58a',
        700: '#2e7c67',
        800: '#1e5245',
        900: '#0f2922',
    },
    // redAccent: {
    //     100: "#f8dcdb",
    //     200: "#f1b9b7",
    //     300: "#e99592",
    //     400: "#e2726e",
    //     500: "#db4f4a",
    //     600: "#af3f3b",
    //     700: "#832f2c",
    //     800: "#58201e",
    //     900: "#2c100f"
    // },
    redAccent: {
        100: '#e2d3d3',
        200: '#c6a7a7',
        300: '#a97c7c',
        400: '#8d5050',
        500: '#702424',
        600: '#5a1d1d',
        700: '#431616',
        800: '#2d0e0e',
        900: '#160707',
    },
    // blueAccent: {
    //     100: "#e1e2fe",
    //     200: "#c3c6fd",
    //     300: "#a4a9fc",
    //     400: "#868dfb",
    //     500: "#6870fa",
    //     600: "#535ac8",
    //     700: "#3e4396",
    //     800: "#2a2d64",
    //     900: "#151632"
    // },
    blueAccent: {
        100: '#d3dae4',
        200: '#a7b5c9',
        300: '#7a8fad',
        400: '#4e6a92',
        500: '#224577',
        600: '#1b375f',
        700: '#142947',
        800: '#0e1c30',
        900: '#070e18',
    },
    purpleAccent: {
        100: '#eeddfd',
        200: '#dcbbfc',
        300: '#cb99fa',
        400: '#b977f9',
        500: '#a855f7',
        600: '#8644c6',
        700: '#653394',
        800: '#432263',
        900: '#221131',
    },
    yellowAccent: {
        100: '#fcf6cc',
        200: '#f8ed9a',
        300: '#f5e467',
        400: '#f1db35',
        500: '#eed202',
        600: '#bea802',
        700: '#8f7e01',
        800: '#5f5401',
        900: '#302a00',
    },
};

const lightColors = {
    grey: {
        100: '#141414',
        200: '#292929',
        300: '#3d3d3d',
        400: '#525252',
        500: '#666666',
        600: '#858585',
        700: '#a3a3a3',
        800: '#c2c2c2',
        900: '#e0e0e0',
    },
    primary: {
        100: '#040509',
        200: '#080b12',
        300: '#0c101b',
        400: '#f2f0f0',
        500: '#141b2d',
        600: '#434957',
        700: '#727681',
        800: '#a1a4ab',
        900: '#d0d1d5',
    },
    // primary: {
    //     100: "#d2d2d2",
    //     200: "#a5a5a5",
    //     300: "#797979",
    //     400: "#4c4c4c",
    //     500: "#1f1f1f",
    //     600: "#191919",
    //     700: "#131313",
    //     800: "#0c0c0c",
    //     900: "#060606"
    // },
    // primary: {
    //     100: "#060606",
    //     200: "#0c0c0c",
    //     300: "#131313",
    //     400: "#191919",
    //     500: "#1f1f1f",
    //     600: "#4c4c4c",
    //     700: "#797979",
    //     800: "#a5a5a5",
    //     900: "#d2d2d2",
    // },
    greenAccent: {
        100: '#0f2922',
        200: '#1e5245',
        300: '#2e7c67',
        400: '#3da58a',
        500: '#4cceac',
        600: '#70d8bd',
        700: '#94e2cd',
        800: '#b7ebde',
        900: '#dbf5ee',
    },
    redAccent: {
        100: '#2c100f',
        200: '#58201e',
        300: '#832f2c',
        400: '#af3f3b',
        500: '#db4f4a',
        600: '#e2726e',
        700: '#e99592',
        800: '#f1b9b7',
        900: '#f8dcdb',
    },
    // blueAccent: {
    //     100: "#151632",
    //     200: "#2a2d64",
    //     300: "#3e4396",
    //     400: "#535ac8",
    //     500: "#6870fa",
    //     600: "#868dfb",
    //     700: "#a4a9fc",
    //     800: "#c3c6fd",
    //     900: "#e1e2fe",
    // },
    blueAccent: {
        900: '#d3dae4',
        800: '#a7b5c9',
        700: '#7a8fad',
        600: '#4e6a92',
        500: '#224577',
        400: '#1b375f',
        300: '#142947',
        200: '#0e1c30',
        100: '#070e18',
    },
    purpleAccent: {
        100: '#221131',
        200: '#432263',
        300: '#653394',
        400: '#8644c6',
        500: '#a855f7',
        600: '#b977f9',
        700: '#cb99fa',
        800: '#dcbbfc',
        900: '#eeddfd',
    },
    // yellowAccent: {
    //     100: "#fcf6cc",
    //     200: "#f8ed9a",
    //     300: "#f5e467",
    //     400: "#f1db35",
    //     500: "#eed202",
    //     600: "#bea802",
    //     700: "#8f7e01",
    //     800: "#5f5401",
    //     900: "#302a00"
    // },
    yellowAccent: {
        100: '#fff2cc',
        200: '#ffe599',
        300: '#ffd966',
        400: '#ffcc33',
        500: '#ffbf00',
        600: '#cc9900',
        700: '#997300',
        800: '#664c00',
        900: '#332600',
    },
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const themeSettings = (mode: 'light' | 'dark') => {
    const colors = getColors(mode);

    return {
        palette: {
            mode,
            ...(mode === 'dark'
                ? {
                      primary: {
                          main: colors.primary[500],
                      },
                      secondary: {
                          main: colors.greenAccent[500],
                      },
                      neutral: {
                          dark: colors.grey[700],
                          main: colors.grey[500],
                          light: colors.grey[100],
                      },
                      background: {
                          default: colors.primary[500],
                      },
                  }
                : {
                      primary: {
                          main: colors.primary[100],
                      },
                      secondary: {
                          main: colors.greenAccent[500],
                      },
                      neutral: {
                          dark: colors.grey[700],
                          main: colors.grey[500],
                          light: colors.grey[100],
                      },
                      background: {
                          default: '#fcfcfc',
                      },
                  }),
        },
        typography: {
            fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
            fontSize: 12,
            h1: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: '40',
            },
            h2: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: '32',
            },
            h3: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: '24',
            },
            h4: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: '20',
            },
            h5: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: '16',
            },
            h6: {
                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                fontSize: '14',
            },
        },
    };
};

// context for color mode
export const ColorModeContext = createContext({
    toggleColorMode: () => {},
    setColorMode: (mode: 'light' | 'dark') => {},
});

export const useMode = (): any => {
    const [mode, setMode] = useState('dark' as 'light' | 'dark');

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prev) => (prev === 'light' ? 'dark' : 'light'));
            },
            setColorMode: (newMode: 'light' | 'dark') => {
                setMode(newMode);
            },
        }),
        [],
    );

    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

    return [theme, colorMode];
};
