import React, { useState } from 'react';

const ErrorBoundary = ({ children }: any): JSX.Element => {
    const [error, setError] = useState(null) as any;
    const [errorInfo, setErrorInfo] = useState(null) as any;

    const logErrorToMyService = (_error: any, _errorInfo: any): void => {
        // log error to service
    };

    if (error) {
        return (
            <div>
                <h2>Something went wrong.</h2>
                <details style={{ whiteSpace: 'pre-wrap' }}>
                    {error?.toString()}
                    <br />
                    {errorInfo?.componentStack}
                </details>
            </div>
        );
    }

    return children;
};

export default ErrorBoundary;
