import { apiSlice } from './apiSlice';
const AUTH_URL = '/v1/auth';

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        logout: builder.mutation({
            query: () => ({
                url: `${AUTH_URL}/logout`,
                method: 'POST',
                credentials: 'include',
            }),
        }),
        getUser: builder.query({
            query: () => ({
                url: `${AUTH_URL}/user`,
                method: 'GET',
                credentials: 'include',
            }),
        }),
    }),
});

export const { useLogoutMutation, useGetUserQuery } = authApiSlice;
