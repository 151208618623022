import { Box, Typography, useTheme } from '@mui/material';
import { getColors } from '@src/global/context/theme';
import ProgressCircle from '@src/global/components/ProgressCircle';

const StatBox = ({
    title,
    subtitle,
    icon,
    progress,
    increase,
    change,
    noProgressCircle = false,
}: any): JSX.Element => {
    const theme = useTheme();
    const colors = getColors(theme.palette.mode);

    return (
        <Box width="100%" m="0 30px">
            <Box display="flex" justifyContent="space-between">
                <Box>
                    {icon}
                    <Typography
                        variant="h4"
                        fontWeight="bold"
                        sx={{ color: colors.grey[100] }}
                    >
                        {title}
                    </Typography>
                </Box>
                {noProgressCircle ? null : (
                    <Box>
                        <ProgressCircle progress={progress} />
                    </Box>
                )}
            </Box>
            <Box display="flex" justifyContent="space-between" mt="2px">
                <Typography
                    variant="h5"
                    sx={{ color: colors.greenAccent[500] }}
                >
                    {subtitle}
                </Typography>
                <Typography
                    variant="h5"
                    fontStyle="italic"
                    sx={
                        increase
                            ? { color: colors.greenAccent[500] }
                            : { color: colors.redAccent[500] }
                    }
                >
                    {change}
                </Typography>
            </Box>
        </Box>
    );
};

export default StatBox;
