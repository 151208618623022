import React, { useContext } from 'react';
import { Box, Typography, Icon, useTheme, Link } from '@mui/material';
import { getColors } from '@src/global/context/theme';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import { Link as LinkRouter } from 'react-router-dom';
import { SidebarContext } from '@src/global/context/sidebar';

const Error404 = (): JSX.Element => {
    const theme = useTheme();
    const colors = getColors(theme.palette.mode);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '90vh',
                    // backgroundColor: colors.primary[500],
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant="h1"
                        style={{
                            color: colors.redAccent[400],
                            fontSize: '10vh',
                            padding: '20px',
                        }}
                    >
                        404
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                left: '0',
                            }}
                        >
                            <WarningOutlinedIcon
                                style={{
                                    color: colors.redAccent[400],
                                    fontSize: '5vh',
                                }}
                            />
                            <Typography
                                variant="h1"
                                style={{
                                    color: colors.grey[100],
                                    fontSize: '3vh',
                                    padding: '1vh',
                                }}
                            >
                                Oops! Page not found.
                            </Typography>
                        </Box>
                        <Typography
                            variant="h3"
                            style={{ color: colors.grey[100], padding: '1vh' }}
                        >
                            We could not find the page you were looking for.
                            <br />
                            Meanwhile, you may return to the
                            <Link
                                sx={{
                                    color: colors.blueAccent[200],
                                    textDecoration: 'none',
                                    '&:hover': {
                                        cursor: 'pointer',
                                        textDecoration: 'underline',
                                    },
                                }}
                            >
                                <LinkRouter
                                    to="/"
                                    style={{
                                        color: 'inherit',
                                        textDecoration: 'inherit',
                                    }}
                                >
                                    {' '}
                                    dashboard
                                </LinkRouter>
                            </Link>{' '}
                            🧁
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default Error404;
