import { createContext, useReducer, useEffect } from 'react';

// Load from Local Storage, if exists
const shoppingList = localStorage.getItem('shoppingList')
    ? JSON.parse(String(localStorage.getItem('shoppingList')))
    : [];
const sidebarCollapsed = localStorage.getItem('sidebarCollapsed')
    ? JSON.parse(String(localStorage.getItem('sidebarCollapsed')))
    : false;
const initState = {
    shoppingList,
    sidebarCollapsed,
};

export const GlobalContext = createContext(initState as any);

const AppReducer = (state: any, action: any): any => {
    switch (action.type) {
        case 'ADD_ITEM':
            return {
                shoppingList: [action.payload, ...state.shoppingList],
            };
        case 'REMOVE_ITEM':
            return {
                shoppingList: state.shoppingList.filter(
                    (item: any) => item !== action.payload,
                ),
            };
        case 'TOGGLE_SIDEBAR':
            return {
                sidebarCollapsed: !state.sidebarCollapsed,
            };
        default:
            return state;
    }
};

export const GlobalContextProvider = ({ children }: any): JSX.Element => {
    const [state, dispatch] = useReducer(AppReducer, initState);

    // Save to LocalStorage
    useEffect(() => {
        localStorage.setItem(
            'sidebarCollapsed',
            JSON.stringify(state.sidebarCollapsed),
        );
    }, [state.sidebarCollapsed]);
    // Save to LocalStorage

    function addItemToShoppingList(item: any): void {
        dispatch({
            type: 'ADD_ITEM',
            payload: item,
        });
    }
    function removeItemFromShoppingList(item: any): void {
        dispatch({
            type: 'REMOVE_ITEM',
            payload: item,
        });
    }
    function toggleSidebar(): void {
        dispatch({
            type: 'TOGGLE_SIDEBAR',
            payload: {},
        });
    }

    return (
        <GlobalContext.Provider
            value={{
                shoppingList: state.shoppingList,
                addItemToShoppingList,
                removeItemFromShoppingList,
                sidebarCollapsed: state.sidebarCollapsed,
                toggleSidebar,
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};
