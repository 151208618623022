import { createContext, useState, useMemo } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { any } from 'prop-types';

export const SidebarContext = createContext({} as any);

export const SidebarContextProvider = ({ children }: any): JSX.Element => {
    const [selected, setSelected] = useState('Dashboard');

    return (
        <SidebarContext.Provider value={[selected, setSelected]}>
            {children}
        </SidebarContext.Provider>
    );
};
