import React from 'react';
import { Typography, Box, useTheme, Link } from '@mui/material';
import { ColorModeContext, getColors } from '@src/global/context/theme';

function Footer(): JSX.Element {
    const theme = useTheme();
    const colors = getColors(theme.palette.mode);

    const showTerms = (): void => {};

    const showPrivacyPolicy = (): void => {};

    return (
        <>
            <Box
                position="fixed"
                display="flex"
                justifyContent="center"
                alignItems="center"
                left={0}
                right={0}
                bottom={0}
                width="100%"
            >
                <Typography
                    sx={{ color: colors.blueAccent[100] }}
                    variant="subtitle1"
                >
                    © USA Cyber Services Group {new Date().getFullYear()} |{' '}
                    <Link
                        target="_blank"
                        href="https://robloxnusa.com/terms-of-service"
                        sx={{ color: 'inherit', textDecoration: 'inherit' }}
                    >
                        Terms & Conditions
                    </Link>{' '}
                    |{' '}
                    <Link
                        target="_blank"
                        href="https://robloxnusa.com/privacy-policy"
                        sx={{ color: 'inherit', textDecoration: 'inherit' }}
                    >
                        Privacy Policy
                    </Link>{' '}
                    |{' '}
                    <Link
                        target="_blank"
                        href="#"
                        sx={{ color: 'inherit', textDecoration: 'inherit' }}
                    >
                        Service Status
                    </Link>{' '}
                    |{' '}
                    <Link
                        target="_blank"
                        href="https://discord.com/invite/xX3abhvfqU"
                        sx={{ color: 'inherit', textDecoration: 'inherit' }}
                    >
                        Moderation Services Discord
                    </Link>
                </Typography>
            </Box>
        </>
    );
}

export default Footer;
