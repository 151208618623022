import { useEffect, useContext, useState } from 'react';
import { Box, Button, ButtonGroup, IconButton, useTheme } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { getColors } from '@src/global/context/theme';

import DownloadIcon from '@mui/icons-material/Download';
import PsychologyIcon from '@mui/icons-material/Psychology';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Header from '@src/global/components/Header';
import { DataTableContext } from '@src/global/context/dataTables';
import axios from 'axios';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useGetTableDataQuery } from '@src/slices/electionsApiSlice';

const BallotControls = (): JSX.Element => {
    const dispatch = useDispatch();
    const location = useLocation();
    const theme = useTheme();
    const colors = getColors(theme.palette.mode);

    const getTableData = useGetTableDataQuery(
        document.location.pathname.split('/')[2],
    );
    const { tableData } = useSelector((state: any) => state.dataTable);

    const [electionType, setElectionType] = useState('Active Elections');

    useEffect(() => {
        refreshTableData({ doToast: false });
    }, [location.pathname]);

    const refreshTableData = (
        { doToast = true } = { doToast: false },
    ): void => {
        if (doToast) {
            toast(
                (t) => (
                    <span
                        onClick={() => {
                            toast.dismiss(t.toastProps.toastId);
                        }}
                    >
                        Successfully reloaded table.
                    </span>
                ),
                { icon: <>✅</>, type: 'success' },
            );
        }

        void getTableData
            .refetch()
            .unwrap()
            .then((data: any) => {
                if (data?.success) {
                    dispatch({
                        type: 'dataTable/setTableData',
                        payload: {
                            key: location.pathname.split('/')[2],
                            data: data.data,
                        },
                    });
                }
            });
    };

    const columns = [
        {
            field: 'upload_Time',
            headerName: 'Upload Time',
            type: 'dateTime',
            flex: 1,
            valueGetter: ({ value }: any) => value && new Date(String(value)),
        },
        {
            field: 'file_Name',
            headerName: 'File Name',
            flex: 2,
        },
        {
            field: 'uploaded_By',
            headerName: 'Uploaded By',
            flex: 1,
            cellClassName: 'name-column--cell',
        },
        {
            field: 'controls',
            headerName: 'Controls',
            flex: 1,
            renderCell: ({ row }: any) => {
                const deleteRequest = (): void => {
                    void Swal.fire({
                        title: 'Delete File',
                        text: "You're about to delete a file! Are you sure you want to do this?",
                        showConfirmButton: true,
                        showDenyButton: true,
                        icon: 'warning',
                    }).then(async (value) => {
                        if (value.isDenied) {
                            void Swal.fire({
                                title: 'Delete File',
                                text: 'Successfully cancelled.',
                                allowEnterKey: true,
                                icon: 'error',
                            });
                        }
                        if (value.isConfirmed) {
                            await axios
                                .delete(
                                    (process.env.REACT_APP_API_URI as string) +
                                        `/storage/${document.location.pathname.split('/')[2]}`,
                                    {
                                        withCredentials: true,
                                        data: {
                                            file: row.file_Name,
                                        },
                                    },
                                )
                                .catch(() => {
                                    toast(
                                        (t) => (
                                            <span
                                                onClick={() => {
                                                    toast.dismiss(
                                                        t.toastProps.toastId,
                                                    );
                                                }}
                                            >
                                                No response from server. Please
                                                consult your local developer.
                                            </span>
                                        ),
                                        { icon: <>❌</>, type: 'error' },
                                    );
                                })
                                .then((res) => {
                                    if (
                                        res &&
                                        res.status === 200 &&
                                        res.data.success
                                    ) {
                                        toast(
                                            (t) => (
                                                <span
                                                    onClick={() => {
                                                        toast.dismiss(
                                                            t.toastProps
                                                                .toastId,
                                                        );
                                                    }}
                                                >
                                                    Successfully deleted file.
                                                </span>
                                            ),
                                            { icon: <>✅</>, type: 'success' },
                                        );
                                        refreshTableData({ doToast: false });
                                        // setDeleted(true);
                                        //   tableData.setTableData(tableData.getTableData.filter((item: any) => { return item.file_Name != data.file_Name }));
                                    } else {
                                        toast(
                                            (t) => (
                                                <span
                                                    onClick={() => {
                                                        toast.dismiss(
                                                            t.toastProps
                                                                .toastId,
                                                        );
                                                    }}
                                                >
                                                    Failed to delete file.
                                                </span>
                                            ),
                                            { icon: <>❌</>, type: 'error' },
                                        );
                                    }
                                });
                        }
                    });
                };

                const downloadRequest = (): void => {
                    axios
                        .get(
                            (process.env.REACT_APP_API_URI as string) +
                                `/storage/${document.location.pathname.split('/')[2]}`,
                            {
                                withCredentials: true,
                                params: {
                                    file: row.file_Name,
                                },
                                responseType: 'blob',
                            },
                        )
                        .then((res) => {
                            const href = URL.createObjectURL(res.data as Blob);

                            const link = document.createElement('a');
                            link.href = href;
                            link.setAttribute(
                                'download',
                                String(row.file_Name),
                            );
                            document.body.appendChild(link);
                            link.click();

                            document.body.removeChild(link);
                            URL.revokeObjectURL(href);

                            if (res.status === 200) {
                                toast(
                                    (t) => (
                                        <span
                                            onClick={() => {
                                                toast.dismiss(
                                                    t.toastProps.toastId,
                                                );
                                            }}
                                        >
                                            Successfully downloaded file.
                                        </span>
                                    ),
                                    { icon: <>✅</>, type: 'success' },
                                );
                            } else {
                                toast(
                                    (t) => (
                                        <span
                                            onClick={() => {
                                                toast.dismiss(
                                                    t.toastProps.toastId,
                                                );
                                            }}
                                        >
                                            Failed to download file.
                                        </span>
                                    ),
                                    { icon: <>❌</>, type: 'error' },
                                );
                            }
                        })
                        .catch(() => {
                            toast(
                                (t) => (
                                    <span
                                        onClick={() => {
                                            toast.dismiss(t.toastProps.toastId);
                                        }}
                                    >
                                        No response from server. Please consult
                                        your local developer.
                                    </span>
                                ),
                                { icon: <>❌</>, type: 'error' },
                            );
                        });
                };

                const processRequest = (): void => {
                    void Swal.fire({
                        title: 'Process File',
                        html: `Are you sure you want to process? <br><br>${row.file_Name}`,
                        showConfirmButton: true,
                        confirmButtonText: 'Process',
                        showDenyButton: true,
                        denyButtonText: 'Abort',
                        icon: 'warning',
                    }).then((res) => {
                        if (res.isConfirmed) {
                            axios
                                .post(
                                    (process.env.REACT_APP_API_URI as string) +
                                        '/storage/isd/process',
                                    {
                                        action: 'process',
                                        filename: row.file_Name,
                                    },
                                    {
                                        withCredentials: true,
                                    },
                                )
                                .then(function (response) {
                                    if (response.data.success === false) {
                                        void Swal.fire({
                                            title: 'Process File',
                                            html:
                                                'An error occured while trying to process.<br><br>' +
                                                JSON.stringify(
                                                    response.data.error,
                                                ),
                                            allowEnterKey: true,
                                            icon: 'error',
                                        });
                                        return;
                                    }
                                    if (
                                        document.location.pathname.split('/')[2]
                                    ) {
                                        refreshTableData();
                                    }
                                })
                                .catch(function (error) {
                                    void Swal.fire({
                                        title: 'Process File',
                                        text: 'An error occured. The server could not be contacted properly.',
                                        allowEnterKey: true,
                                        icon: 'error',
                                    });
                                    console.log(error);
                                });
                        }
                    });
                };

                return (
                    <Box
                        sx={{
                            width: '60%',
                            p: '5px',
                            display: 'flex',
                            borderRadius: '4px',
                        }}
                    >
                        <IconButton
                            onClick={downloadRequest}
                            color="secondary"
                            aria-label="download picture"
                            component="label"
                        >
                            <DownloadIcon />
                        </IconButton>
                        <IconButton
                            onClick={processRequest}
                            color="info"
                            aria-label="process picture"
                            component="label"
                        >
                            <PsychologyIcon />
                        </IconButton>
                        <IconButton
                            disabled
                            color="info"
                            aria-label="visibility picture"
                            component="label"
                        >
                            <VisibilityOutlinedIcon />
                        </IconButton>
                        <IconButton
                            disabled
                            color="warning"
                            aria-label="edit picture"
                            component="label"
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            onClick={deleteRequest}
                            color="error"
                            aria-label="delete picture"
                            component="label"
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                );
            },
        },
    ];

    const customOverlayTemplate = (props: any): JSX.Element => {
        return (
            <Box
                className="MuiDataGrid-overlay"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    // zIndex: 2,
                    '& .ant-empty-img-1': {
                        fill:
                            theme.palette.mode === 'light'
                                ? colors.blueAccent[700]
                                : colors.blueAccent[300],
                    },
                    '& .ant-empty-img-2': {
                        fill:
                            theme.palette.mode === 'light'
                                ? colors.blueAccent[700]
                                : colors.blueAccent[300],
                    },
                    '& .ant-empty-img-3': {
                        fill:
                            theme.palette.mode === 'light'
                                ? colors.blueAccent[600]
                                : colors.blueAccent[500],
                    },
                    '& .ant-empty-img-4': {
                        fill:
                            theme.palette.mode === 'light'
                                ? colors.blueAccent[800]
                                : colors.blueAccent[200],
                    },
                    '& .ant-empty-img-5': {
                        fillOpacity:
                            theme.palette.mode === 'light' ? '0.8' : '0.2',
                        fill:
                            theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
                    },
                }}
            >
                <svg
                    width="120"
                    height="100"
                    viewBox="0 0 184 152"
                    aria-hidden
                    focusable="false"
                >
                    <g fill="none" fillRule="evenodd">
                        <g transform="translate(24 31.67)">
                            <ellipse
                                className="ant-empty-img-5"
                                cx="67.797"
                                cy="106.89"
                                rx="67.797"
                                ry="12.668"
                            />
                            <path
                                className="ant-empty-img-1"
                                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                            />
                            <path
                                className="ant-empty-img-2"
                                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                            />
                            <path
                                className="ant-empty-img-3"
                                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                            />
                        </g>
                        <path
                            className="ant-empty-img-3"
                            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                        />
                        <g
                            className="ant-empty-img-4"
                            transform="translate(149.65 15.383)"
                        >
                            <ellipse
                                cx="20.654"
                                cy="3.167"
                                rx="2.849"
                                ry="2.815"
                            />
                            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                        </g>
                    </g>
                </svg>
                {props}
            </Box>
        );
    };

    function CustomNoRowsOverlay(): JSX.Element {
        return customOverlayTemplate(
            <>
                <Box sx={{ mt: 1 }}>No Rows</Box>
                <pre>(rows=&#123;[]&#125;)</pre>
            </>,
        );
    }

    function CustomNoResultsOverlay(): JSX.Element {
        return customOverlayTemplate(
            <Box sx={{ mt: 1 }}>No Results from Filter</Box>,
        );
    }

    return (
        <>
            <Box m="20px">
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Header
                        title="Ballot Controls"
                        subtitle="Controlling your Ballots"
                    />
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: colors.greenAccent[600],
                            height: '3vh',
                            width: '8rem',
                            ml: 'auto',
                            mr: '2vw',
                        }}
                        onClick={() => {
                            setElectionType('Inactive Elections');
                        }}
                    >
                        Create Signup
                    </Button>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        width: '100%',
                        height: '6vh',
                        backgroundColor: colors.primary[400],
                        borderRadius: '8px',
                        '& button:hover': {
                            backgroundColor: colors.blueAccent[400],
                        },
                    }}
                >
                    <ButtonGroup
                        variant="contained"
                        aria-label="button group"
                        fullWidth
                        sx={{
                            m: 2,
                            width: '70vw',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Button
                            sx={{
                                backgroundColor:
                                    electionType === 'Active Elections'
                                        ? colors.blueAccent[500]
                                        : '',
                            }}
                            onClick={() => {
                                setElectionType('Active Elections');
                            }}
                        >
                            Active Elections
                        </Button>
                        <Button
                            sx={{
                                backgroundColor:
                                    electionType === 'Inactive Elections'
                                        ? colors.blueAccent[500]
                                        : '',
                            }}
                            onClick={() => {
                                setElectionType('Inactive Elections');
                            }}
                        >
                            Inactive Elections
                        </Button>
                    </ButtonGroup>
                </Box>
                <Box
                    m="10px 0 0 0"
                    height="70vh"
                    sx={{
                        '& .MuiDataGrid-root': {
                            border: 'none',
                        },
                        '& .MuiDataGrid-cell': {
                            borderBottom: 'none',
                            zIndex: 1,
                        },
                        '& .name-column--cell': {
                            color: colors.greenAccent[300],
                        },
                        '& .MuiDataGrid-row': {
                            '&:nth-of-type(2n)': {
                                backgroundColor: colors.blueAccent[900],
                            },
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: colors.blueAccent[700],
                            borderBottom: 'none',
                        },
                        '& .MuiDataGrid-virtualScroller': {
                            backgroundColor: colors.primary[400],
                            zIndex: tableData[location.pathname.split('/')[2]]
                                ? tableData[location.pathname.split('/')[2]]
                                      .length > 0
                                    ? 1
                                    : -1
                                : -1,
                        },
                        '& .MuiDataGrid-footerContainer': {
                            borderTop: 'none',
                            backgroundColor: colors.blueAccent[700],
                        },
                        '& .MuiCheckbox-root': {
                            color: `${colors.greenAccent[200]} !important`,
                        },
                        '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                            color: `${colors.grey[100]} !important`,
                        },
                        '& .MuiDataGrid-toolbarContainer .MuiButton-text:hover':
                            {
                                color: `${colors.grey[200]} !important`,
                            },
                    }}
                >
                    <DataGrid
                        checkboxSelection
                        rows={
                            tableData[location.pathname.split('/')[2]]
                                ? tableData[location.pathname.split('/')[2]]
                                : []
                        } // ISDTableDataISD
                        columns={columns as any}
                        getRowId={(row) => {
                            if (row._id) {
                                return row._id;
                            }
                            return 'RIP';
                        }}
                        components={{
                            Toolbar: GridToolbar,
                            NoRowsOverlay: CustomNoRowsOverlay,
                            NoResultsOverlay: CustomNoResultsOverlay,
                        }}
                    />

                    {/* Bulk Controls */}
                    {/* <Box
                        sx={{
                            marginTop: "1vh",
                            width: "6%",
                            backgroundColor: colors.primary[400],
                            borderRadius: "8px",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <IconButton disabled color="secondary" aria-label="download picture" component="label">
                            <DownloadIcon />
                        </IconButton>
                        <IconButton disabled color="error" aria-label="delete picture" component="label">
                            <DeleteIcon />
                        </IconButton>
                    </Box> */}
                </Box>
            </Box>
        </>
    );
};

export default BallotControls;
