import { useEffect, useContext, useState, useRef, useMemo } from 'react';
import {
    Box,
    Button,
    ButtonGroup,
    IconButton,
    type TablePaginationProps,
    useTheme,
    Pagination as MuiPagination,
    Chip,
    Avatar,
    Paper,
    Typography,
} from '@mui/material';
import {
    DataGrid,
    GridCheckIcon,
    GridCloseIcon,
    GridPagination,
    GridRowId,
    GridToolbar,
    gridPageCountSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import { getColors } from '@src/global/context/theme';

import DownloadIcon from '@mui/icons-material/Download';
import PsychologyIcon from '@mui/icons-material/Psychology';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Header from '@src/global/components/Header';
import axios from 'axios';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
    useGetStaffDataQuery,
    useGetThumbnailsQuery,
} from '@src/slices/dataTableApiSlice';

const logs = (): JSX.Element => {
    const dispatch = useDispatch();
    const location = useLocation();
    const theme = useTheme();
    const colors = getColors(theme.palette.mode);

    const getStaffData = useGetStaffDataQuery('');
    const { staffData } = useSelector((state: any) => state.dataTable);

    const [electionType, setElectionType] = useState('Active Bans');

    useEffect(() => {
        refreshTableData(false);
    }, [location.pathname]);

    const refreshTableData = (doToast: boolean = false): void => {
        if (doToast) {
            toast(
                (t) => (
                    <span
                        onClick={() => {
                            toast.dismiss(t.toastProps.toastId);
                        }}
                    >
                        Successfully reloaded table.
                    </span>
                ),
                { icon: <>✅</>, type: 'success' },
            );
        }

        void getStaffData
            .refetch()
            .unwrap()
            .then((newStaffData: any) => {
                if (newStaffData?.success) {
                    // getThumbnails.refetch().unwrap().then((thumbnailData: any) => {
                    // if (thumbnailData && thumbnailData.success) {
                    //     dispatch({ type: 'dataTable/setStaffData', payload: { data: { staff: staffData.staff.map((staff: any) => ({ ...staff, avatar: thumbnailData.data.find((thumbnail: any) => { return thumbnail.user_id == staff.user_id }).thumbnail.value }))} } });
                    // } else {
                    dispatch({
                        type: 'dataTable/setStaffData',
                        payload: { data: { staff: newStaffData.staff } },
                    });
                }
            });
    };

    const columns = [
        {
            field: 'user_id',
            headerName: 'User',
            flex: 0.35,
            minWidth: 155,
            renderCell: ({ row }: any) => {
                return (
                    <>
                        <Paper
                            elevation={6}
                            sx={{
                                width: '100%',
                                p: '5px',
                                borderRadius: '4px',
                                display: 'flex',
                                gap: '10px',
                                alignItems: 'center',
                                mt: '7px',
                                mb: '7px',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    p: '5px',
                                    borderRadius: '4px',
                                    display: 'flex',
                                    gap: '10px',
                                }}
                            >
                                <Avatar
                                    src={row.thumbnail.thumbnail.value}
                                    variant="rounded"
                                    sx={{
                                        minWidth: 64,
                                        minHeight: 64,
                                        border: `3px solid ${colors.primary[700]}`,
                                        backgroundColor: colors.primary[700],
                                    }}
                                />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        gap: '5px',
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        sx={{ color: colors.primary[100] }}
                                    >
                                        {row.username}
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        sx={{ color: colors.primary[200] }}
                                    >
                                        {row.user_id}
                                    </Typography>
                                </Box>
                            </Box>
                        </Paper>
                    </>
                );
            },
        },
        {
            field: 'dev',
            headerName: 'isDev',
            type: 'boolean',
            flex: 0.25,
            renderCell: (params: any) =>
                params.value ? (
                    <GridCheckIcon
                        style={{
                            color: colors.greenAccent[500],
                        }}
                    />
                ) : (
                    <GridCloseIcon
                        style={{
                            color: colors.redAccent[300],
                        }}
                    />
                ),
        },
        {
            field: 'senior',
            headerName: 'isSenior',
            type: 'boolean',
            flex: 0.25,
            renderCell: (params: any) =>
                params.value ? (
                    <GridCheckIcon
                        style={{
                            color: colors.greenAccent[500],
                        }}
                    />
                ) : (
                    <GridCloseIcon
                        style={{
                            color: colors.redAccent[300],
                        }}
                    />
                ),
        },
        {
            field: 'instructor',
            headerName: 'isInstructor',
            type: 'boolean',
            flex: 0.25,
            renderCell: (params: any) =>
                params.value ? (
                    <GridCheckIcon
                        style={{
                            color: colors.greenAccent[500],
                        }}
                    />
                ) : (
                    <GridCloseIcon
                        style={{
                            color: colors.redAccent[300],
                        }}
                    />
                ),
        },
        {
            field: 'mod',
            headerName: 'isMod',
            type: 'boolean',
            flex: 0.25,
            renderCell: (params: any) =>
                params.value ? (
                    <GridCheckIcon
                        style={{
                            color: colors.greenAccent[500],
                        }}
                    />
                ) : (
                    <GridCloseIcon
                        style={{
                            color: colors.redAccent[300],
                        }}
                    />
                ),
        },
        {
            field: 'controls',
            headerName: 'Controls',
            flex: 1,
            renderCell: ({ row }: any) => {
                const deleteRequest = (): void => {
                    void Swal.fire({
                        title: 'Delete File',
                        text: "You're about to delete a file! Are you sure you want to do this?",
                        showConfirmButton: true,
                        showDenyButton: true,
                        icon: 'warning',
                    }).then(async (value) => {
                        if (value.isDenied) {
                            void Swal.fire({
                                title: 'Delete File',
                                text: 'Successfully cancelled.',
                                allowEnterKey: true,
                                icon: 'error',
                            });
                        }
                        if (value.isConfirmed) {
                            await axios
                                .delete(
                                    (process.env.REACT_APP_API_URI as string) +
                                        `/storage/${document.location.pathname.split('/')[2]}`,
                                    {
                                        withCredentials: true,
                                        data: {
                                            file: row.file_Name,
                                        },
                                    },
                                )
                                .catch(() => {
                                    toast(
                                        (t) => (
                                            <span
                                                onClick={() => {
                                                    toast.dismiss(
                                                        t.toastProps.toastId,
                                                    );
                                                }}
                                            >
                                                No response from server. Please
                                                consult your local developer.
                                            </span>
                                        ),
                                        { icon: <>❌</>, type: 'error' },
                                    );
                                })
                                .then((res) => {
                                    if (
                                        res &&
                                        res.status === 200 &&
                                        res.data.success
                                    ) {
                                        toast(
                                            (t) => (
                                                <span
                                                    onClick={() => {
                                                        toast.dismiss(
                                                            t.toastProps
                                                                .toastId,
                                                        );
                                                    }}
                                                >
                                                    Successfully deleted file.
                                                </span>
                                            ),
                                            { icon: <>✅</>, type: 'success' },
                                        );
                                        refreshTableData(false);
                                        // setDeleted(true);
                                        //   tableData.setTableData(tableData.getBanData.filter((item: any) => { return item.file_Name != data.file_Name }));
                                    } else {
                                        toast(
                                            (t) => (
                                                <span
                                                    onClick={() => {
                                                        toast.dismiss(
                                                            t.toastProps
                                                                .toastId,
                                                        );
                                                    }}
                                                >
                                                    Failed to delete file.
                                                </span>
                                            ),
                                            { icon: <>❌</>, type: 'error' },
                                        );
                                    }
                                });
                        }
                    });
                };

                return (
                    <Box
                        sx={{
                            width: '60%',
                            p: '5px',
                            display: 'flex',
                            borderRadius: '4px',
                        }}
                    >
                        <IconButton
                            disabled
                            onClick={() => {}}
                            color="error"
                            aria-label="delete picture"
                            component="label"
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                );
            },
        },
    ];

    const customOverlayTemplate = (props: any): JSX.Element => {
        return (
            <Box
                className="MuiDataGrid-overlay"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    // zIndex: 2,
                    '& .ant-empty-img-1': {
                        fill:
                            theme.palette.mode === 'light'
                                ? colors.blueAccent[700]
                                : colors.blueAccent[300],
                    },
                    '& .ant-empty-img-2': {
                        fill:
                            theme.palette.mode === 'light'
                                ? colors.blueAccent[700]
                                : colors.blueAccent[300],
                    },
                    '& .ant-empty-img-3': {
                        fill:
                            theme.palette.mode === 'light'
                                ? colors.blueAccent[600]
                                : colors.blueAccent[500],
                    },
                    '& .ant-empty-img-4': {
                        fill:
                            theme.palette.mode === 'light'
                                ? colors.blueAccent[800]
                                : colors.blueAccent[200],
                    },
                    '& .ant-empty-img-5': {
                        fillOpacity:
                            theme.palette.mode === 'light' ? '0.8' : '0.2',
                        fill:
                            theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
                    },
                }}
            >
                <svg
                    width="120"
                    height="100"
                    viewBox="0 0 184 152"
                    aria-hidden
                    focusable="false"
                >
                    <g fill="none" fillRule="evenodd">
                        <g transform="translate(24 31.67)">
                            <ellipse
                                className="ant-empty-img-5"
                                cx="67.797"
                                cy="106.89"
                                rx="67.797"
                                ry="12.668"
                            />
                            <path
                                className="ant-empty-img-1"
                                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                            />
                            <path
                                className="ant-empty-img-2"
                                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                            />
                            <path
                                className="ant-empty-img-3"
                                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                            />
                        </g>
                        <path
                            className="ant-empty-img-3"
                            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                        />
                        <g
                            className="ant-empty-img-4"
                            transform="translate(149.65 15.383)"
                        >
                            <ellipse
                                cx="20.654"
                                cy="3.167"
                                rx="2.849"
                                ry="2.815"
                            />
                            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                        </g>
                    </g>
                </svg>
                {props}
            </Box>
        );
    };

    function CustomNoRowsOverlay(): JSX.Element {
        return customOverlayTemplate(
            <>
                <Box sx={{ mt: 1 }}>No Rows</Box>
                <pre>(rows=&#123;[]&#125;)</pre>
            </>,
        );
    }

    function CustomNoResultsOverlay(): JSX.Element {
        return customOverlayTemplate(
            <Box sx={{ mt: 1 }}>No Results from Filter</Box>,
        );
    }

    function Pagination({
        page,
        onPageChange,
        className,
    }: Pick<
        TablePaginationProps,
        'page' | 'onPageChange' | 'className'
    >): JSX.Element {
        const apiRef = useGridApiContext();
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        return (
            <MuiPagination
                color="primary"
                className={className}
                count={pageCount}
                page={page + 1}
                onChange={(event: any, newPage: any) => {
                    // TODO: Fix this eslint error
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    onPageChange(event, newPage - 1);
                }}
            />
        );
    }

    function CustomPagination(props: any): JSX.Element {
        return <GridPagination ActionsComponent={Pagination} {...props} />;
    }

    return (
        <>
            <Box m="20px">
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        // justifyContent: 'center',
                    }}
                >
                    <Header title="Logs" subtitle="View all Logs" />
                    {/* <Button
                        variant="contained"
                        sx={{
                            backgroundColor: colors.greenAccent[600],
                            height: '3vh',
                            width: '8rem',
                            ml: 'auto',
                            mr: '2vw',
                        }}
                        onClick={() => {}}
                    >
                        Add Staff
                    </Button> */}
                </Box>

                <Box
                    m="10px 0 0 0"
                    height="70vh"
                    sx={{
                        '& .MuiDataGrid-root': {
                            border: 'none',
                        },
                        '& .MuiDataGrid-cell': {
                            lineHeight: 'unset !important',
                            maxHeight: 'none !important',
                            borderBottom: 'none',
                            zIndex: 1,
                        },
                        '& .name-column--cell': {
                            color: colors.greenAccent[300],
                        },
                        '& .MuiDataGrid-row': {
                            maxHeight: 'none !important',
                            '&:nth-of-type(2n)': {
                                backgroundColor: colors.primary[600],
                            },
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: colors.blueAccent[500],
                            borderBottom: 'none',
                        },
                        '& .MuiDataGrid-virtualScroller': {
                            backgroundColor: colors.primary[400],
                            zIndex: staffData
                                ? staffData.staff != null
                                    ? staffData.staff.length > 0
                                        ? 1
                                        : -1
                                    : -1
                                : -1,
                        },
                        '& .MuiDataGrid-footerContainer': {
                            borderTop: 'none',
                            backgroundColor: colors.primary[700],
                        },
                        '& .MuiCheckbox-root': {
                            color: `${colors.greenAccent[200]} !important`,
                        },
                        '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                            color: `${colors.primary[100]} !important`,
                        },
                        '& .MuiDataGrid-toolbarContainer .MuiButton-text:hover':
                            {
                                color: `${colors.primary[200]} !important`,
                            },
                    }}
                >
                    <DataGrid
                        checkboxSelection
                        disableVirtualization
                        rows={staffData ? staffData.staff ?? [] : []} // ISDTableDataISD
                        columns={columns as any}
                        getRowId={(row) => {
                            if (row._id) {
                                return row._id;
                            }
                            return 'RIP';
                        }}
                        components={{
                            Toolbar: GridToolbar,
                            NoRowsOverlay: CustomNoRowsOverlay,
                            NoResultsOverlay: CustomNoResultsOverlay,
                            Pagination: CustomPagination,
                        }}
                    />
                </Box>
            </Box>
        </>
    );
};

export default logs;
