import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    rolesData:
        localStorage.getItem('rolesData') == null
            ? JSON.parse(localStorage.getItem('rolesData') as string)
            : [],
};

const dataTableSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: {
        setRolesData: (state, action) => {
            state.rolesData = action.payload.data;
            localStorage.setItem('rolesData', JSON.stringify(state.rolesData));
        },
    },
});

export const { setRolesData } = dataTableSlice.actions;

export default dataTableSlice.reducer;
