import { useState, useEffect } from 'react';

import {
    Box,
    Button,
    IconButton,
    Typography,
    useTheme,
    Grid,
    Card,
    Link,
    TextField,
    FormControl,
} from '@mui/material';
import { getColors } from '@src/global/context/theme';

import Header from '@src/global/components/Header';

import { useGetThumbnailsQuery } from '@src/slices/dataTableApiSlice';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import axios from 'axios';
import { Form } from 'react-router-dom';

const Bans = (): JSX.Element => {
    const theme = useTheme();
    const colors = getColors(theme.palette.mode);

    const { userInfo } = useSelector((state: any) => state.auth, shallowEqual);

    const [search, setSearch] = useState([] as string[]);
    const [bans, setBans] = useState([] as any[]);
    const getThumbnails = useGetThumbnailsQuery(
        bans
            ? bans.map((ban: any) => {
                  return ban.user_id;
              })
            : [],
    );

    const searchFunction = async (): Promise<void> => {
        try {
            const usernameSearch = (search || [])
                .filter((username: string) => {
                    return isNaN(Number(username)) || Number(username) === 0;
                })
                .join(',');
            const userIdSearch = (search || [])
                .filter((userId: string) => {
                    return !isNaN(Number(userId)) && Number(userId) !== 0;
                })
                .join(',');

            const reqUrl =
                usernameSearch.length > 0
                    ? userIdSearch.length > 0
                        ? process.env.REACT_APP_API_URI +
                          '/v1/moderation/bans?active&reverse&username=' +
                          usernameSearch +
                          '&userId=' +
                          userIdSearch
                        : process.env.REACT_APP_API_URI +
                          '/v1/moderation/bans?active&reverse&username=' +
                          usernameSearch
                    : userIdSearch.length > 0
                      ? process.env.REACT_APP_API_URI +
                        '/v1/moderation/bans?active&reverse&userId=' +
                        userIdSearch
                      : null;

            if (reqUrl == null) return;

            void axios
                .get(reqUrl, {
                    withCredentials: true,
                })
                .then((res) => {
                    const newBans = (
                        res
                            ? res.data
                                ? res.data.bans
                                    ? Array.isArray(res.data.bans)
                                        ? res.data.bans
                                        : []
                                    : []
                                : []
                            : []
                    ) as any[];
                    setBans(newBans);
                });
        } catch (err) {
            console.log(err);
        }
    };

    // useEffect(() => {
    //   if (bans.length >= 0) {
    //     getThumbnails.refetch().unwrap().then((thumbnailData: any) => {
    //       console.log(thumbnailData);
    //         if (thumbnailData && thumbnailData.success) {
    //           console.log(thumbnailData.data);
    //             setBans(bans.map((ban: any) => ({ ...ban, avatar: thumbnailData.data.find((thumbnail: any) => { return thumbnail.user_id == ban.user_id }).thumbnail.value  })));
    //             console.log(bans);
    //         }
    //     });
    //   }
    // }, [bans]);

    return (
        <Box m="20px" height={'auto'}>
            {/* HEADER */}

            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Header
                    title="BAN DATABASE"
                    subtitle={
                        userInfo
                            ? 'Welcome to the ban database, ' +
                              (userInfo
                                  ? userInfo.roblox
                                      ? userInfo.roblox.username
                                          ? userInfo.roblox.username
                                          : 'Default'
                                      : 'Default'
                                  : 'Default') +
                              '! This is where you can view active and previous bans amongst members of the nUSA community.'
                            : 'Welcome to the ban database! This is where you can view active and previous bans amongst members of the nUSA community.'
                    }
                />{' '}
                {/* (👷 COMING SOON 🚧 ) */}
            </Box>

            {/* GRID & CHARTS */}
            <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="140px"
                gap="20px"
            >
                <Card
                    sx={{
                        gridColumn: 'span 12',
                        gridRow: 'span 2',
                        backgroundColor: colors.primary[400],
                        overflow: 'auto',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            // alignItems: "left",
                            flexDirection: 'column',
                            colors: colors.grey[100],
                            p: '15px',
                        }}
                    >
                        <Typography
                            color={colors.grey[100]}
                            variant="h2"
                            fontWeight="600"
                        >
                            Public Ban Lookup
                        </Typography>
                        <Typography
                            color={colors.grey[200]}
                            sx={{ mt: 1 }}
                            variant="h3"
                            fontWeight="600"
                        >
                            If you want to check someone&apos;s current USA ban
                            status, you may use this tool.
                        </Typography>
                        <FormControl sx={{ mt: 3 }} variant="outlined">
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    void searchFunction();
                                }}
                                autoComplete="off"
                                style={{ width: '100%' }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    label="Username"
                                    color="secondary"
                                    sx={{
                                        '&:focus': {
                                            color: colors.grey[100],
                                        },
                                        width: '100%',
                                        mt: 5,
                                    }}
                                    // inputRef={node => {
                                    //   setSearch(node);
                                    // }}
                                    onChange={(e) => {
                                        setSearch(
                                            e.target.value
                                                ? e.target.value.split(',')
                                                : [],
                                        );
                                    }}
                                    variant="outlined"
                                />
                                <Button
                                    variant="contained"
                                    sx={{
                                        mt: 3,
                                        backgroundColor: colors.blueAccent[500],
                                        width: '20%',
                                        minWidth: '160px',
                                    }}
                                    onClick={() => {
                                        void searchFunction();
                                    }}
                                >
                                    Search
                                </Button>
                            </form>
                        </FormControl>
                    </Box>
                </Card>

                {bans && bans.length > 0 && (
                    <Card
                        sx={{
                            gridColumn: 'span 12',
                            gridRow: 'span 2',
                            backgroundColor: colors.primary[400],
                            overflow: 'auto',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                borderBottom: `4px solid ${colors.primary[500]}`,
                                colors: colors.grey[100],
                                p: '15px',
                            }}
                        >
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item xs={3}>
                                    <Typography
                                        color={colors.grey[100]}
                                        variant="h5"
                                        fontWeight="600"
                                    >
                                        User
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography
                                        color={colors.grey[100]}
                                        variant="h5"
                                        fontWeight="600"
                                    >
                                        Date Added
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography
                                        color={colors.grey[100]}
                                        variant="h5"
                                        fontWeight="600"
                                    >
                                        Date Expires
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography
                                        color={colors.grey[100]}
                                        variant="h5"
                                        fontWeight="600"
                                    >
                                        Status
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>

                        {bans.map((ban: any, i: any) => (
                            <Box
                                key={`${ban._id}`}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                borderBottom={`4px solid ${colors.primary[500]}`}
                                p="15px"
                            >
                                <Grid container alignItems="center">
                                    <Grid item xs={3}>
                                        <Box>
                                            <Typography
                                                variant="h4"
                                                fontWeight="600"
                                            >
                                                {ban.user_name}({ban.user_id})
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box>
                                            <Typography
                                                variant="h4"
                                                fontWeight="600"
                                            >
                                                {new Date(
                                                    String(ban.date_created),
                                                ).toLocaleString('en-US')}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                width: '60%',
                                                backgroundColor:
                                                    ban.action ===
                                                    'Uploaded a file'
                                                        ? colors
                                                              .greenAccent[500]
                                                        : colors.redAccent[400],
                                                p: '5px 10px',
                                                borderRadius: '4px',
                                            }}
                                        >
                                            <Typography
                                                variant="h4"
                                                fontWeight="600"
                                            >
                                                {Date.parse(
                                                    String(ban.date_expires),
                                                ) === 0
                                                    ? 'No Expiration.'
                                                    : new Date(
                                                          String(
                                                              ban.date_expires,
                                                          ),
                                                      ).toLocaleString('en-US')}
                                                {/* {new Date(ban.date_expires).toLocaleString('en-US')} */}
                                                {/* {ban.expiration ? new Date(ban.expiration).toLocaleString('en-US') : "PERMANENT"} */}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                width: '40%',
                                                backgroundColor:
                                                    (ban.permanent ||
                                                        ban.blacklist ||
                                                        Date.parse(
                                                            String(
                                                                ban.date_expires,
                                                            ),
                                                        ) > Date.now()) &&
                                                    ban.appealed !== true &&
                                                    ban.revoked !== true
                                                        ? colors
                                                              .greenAccent[500]
                                                        : colors.redAccent[400],
                                                p: '5px 10px',
                                                borderRadius: '4px',
                                            }}
                                        >
                                            <Typography
                                                variant="h4"
                                                fontWeight="600"
                                            >
                                                {(ban.permanent ||
                                                    ban.blacklist ||
                                                    Date.parse(
                                                        String(
                                                            ban.date_expires,
                                                        ),
                                                    ) > Date.now()) &&
                                                ban.appealed !== true &&
                                                ban.revoked !== true
                                                    ? 'ACTIVE'
                                                    : 'INACTIVE'}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        ))}
                    </Card>
                )}
            </Box>
        </Box>
    );
};

export default Bans;
