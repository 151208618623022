import { apiSlice } from './apiSlice';
const DATA_URL = '/v1/roles';

export const dataTableApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        // getBanData: builder.query({
        //     query: ({ page, active }) => ({
        //         url:
        //             page != null
        //                 ? `/v1/moderation/bans/${page}?reverse${active ? '&active' : ''}`
        //                 : `/v1/moderation/bans?reverse${active ? '&active' : ''}`, // ${document.location.pathname.split("/")[2]}
        //         method: 'GET',
        //         credentials: 'include',
        //     }),
        // }),
        getRoleData: builder.query({
            query: () => ({
                url: `${DATA_URL}`, // ${document.location.pathname.split("/")[2]}
                method: 'GET',
                credentials: 'include',
            }),
        }),
    }),
});

export const { useGetRoleDataQuery } = dataTableApiSlice;
