import { useState } from 'react';
import {
    Box,
    Button,
    Typography,
    useTheme,
    Grid,
    Paper,
    Divider,
    List,
    ListItemText,
    ListItem,
} from '@mui/material';
import { getColors } from '@src/global/context/theme';

import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import SouthEastOutlinedIcon from '@mui/icons-material/SouthEastOutlined';

import Header from '@src/global/components/Header';
import StatBox from '@src/global/components/StatBox';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

const TermsOfService = (): JSX.Element => {
    const theme = useTheme();
    const colors = getColors(theme.palette.mode);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ p: 2 }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        ml: '10vw',
                        mr: '10vw',
                    }}
                >
                    <Header
                        title="Terms and Conditions"
                        subtitle="Welcome to USA Cyber Services! In addition to the Roblox Terms of Services, these terms and conditions outline the rules and regulations for the use of USA Cyber Services's Website, located at https://robloxnusa.com. By accessing this website, we assume you accept these terms and conditions. Do not continue to use USA Cyber Services if you do not agree to take all of the terms and conditions stated on this page."
                    />

                    <Paper sx={{ p: 2 }}>
                        <Typography variant="h2" fontWeight={'bold'}>
                            Cookies:
                        </Typography>
                        <Divider sx={{ marginBottom: '1rem' }} light />

                        <Typography variant="h4">
                            The website uses cookies to help personalize your
                            online experience. By accessing USA Cyber Services,
                            you agree to use the required cookies. A cookie is a
                            text file that is placed on your hard disk by a web
                            page server. Cookies cannot be used to run programs
                            or deliver viruses to your computer. Cookies are
                            uniquely assigned to you and can only be read by a
                            web server in the domain that issued the cookie to
                            you.
                        </Typography>
                        <br />
                        <Typography variant="h4">
                            We may use cookies to collect, store, and track
                            information for statistical or marketing purposes to
                            operate our website. You have the ability to accept
                            or decline optional Cookies. There are some required
                            Cookies that are necessary for the operation of our
                            website. These cookies do not require your consent
                            as they always work. Please keep in mind that by
                            accepting required Cookies, you also accept
                            third-party Cookies, which might be used via
                            third-party provided services if you use such
                            services on our website, for example, a video
                            display window provided by third parties and
                            integrated into our website. You can always withdraw
                            your consent by deleting cookies from your browser.
                            Please note that if you delete cookies or refuse to
                            accept them, you might not be able to use all of the
                            features we offer, you may not be able to store your
                            preferences, and some of our pages might not display
                            properly.
                        </Typography>
                    </Paper>

                    <Paper sx={{ p: 2 }}>
                        <Typography variant="h2" fontWeight={'bold'}>
                            License:
                        </Typography>
                        <Divider sx={{ marginBottom: '1rem' }} light />

                        <Typography variant="h4">
                            Unless otherwise stated, USA Cyber Services and/or
                            its licensors own the intellectual property rights
                            for all material on USA Cyber Services. All
                            intellectual property rights are reserved. You may
                            access this from USA Cyber Services for your own
                            personal use subjected to restrictions set in these
                            terms and conditions. You must not:
                        </Typography>
                        <List sx={{ listStyleType: 'disc', pl: 4 }}>
                            <ListItem sx={{ display: 'list-item' }}>
                                Copy or republish material from USA Cyber
                                Services
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                Sell, rent, or sub-license material from USA
                                Cyber Services
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                Reproduce, duplicate or copy material from USA
                                Cyber Services
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                Redistribute content from USA Cyber Services
                            </ListItem>
                        </List>
                    </Paper>

                    <Paper variant="outlined" sx={{ p: 2 }}>
                        <Typography variant="h4" fontWeight={'bold'}>
                            This Agreement shall begin on the date hereof. Parts
                            of this website offer users an opportunity to post
                            and exchange opinions and information in certain
                            areas of the website. USA Cyber Services does not
                            filter, edit, publish or review Comments before
                            their presence on the website. Comments do not
                            reflect the views and opinions of USA Cyber
                            Services, its agents, and/or affiliates. Comments
                            reflect the views and opinions of the person who
                            posts their views and opinions. To the extent
                            permitted by applicable laws, USA Cyber Services
                            shall not be liable for the Comments or any
                            liability, damages, or expenses caused and/or
                            suffered as a result of any use of and/or posting of
                            and/or appearance of the Comments on this website.
                            USA Cyber Services reserves the right to monitor all
                            Comments and remove any Comments that can be
                            considered inappropriate, offensive, or causes
                            breach of these Terms and Conditions.
                        </Typography>
                    </Paper>

                    <Paper sx={{ p: 2 }}>
                        <Typography variant="h2" fontWeight={'bold'}>
                            You warrant and represent that:
                        </Typography>
                        <Divider sx={{ marginBottom: '1rem' }} light />
                        <List sx={{ listStyleType: 'disc', pl: 4 }}>
                            <ListItem sx={{ display: 'list-item' }}>
                                You are entitled to post the Comments on our
                                website and have all necessary licenses and
                                consents to do so;
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                The Comments do not invade any intellectual
                                property right, including without limitation
                                copyright, patent, or trademark of any third
                                party;
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                The Comments do not contain any defamatory,
                                libellous, offensive, indecent, or otherwise
                                unlawful material, which is an invasion of
                                privacy. The Comments will not be used to
                                solicit or promote business or custom or present
                                commercial activities or unlawful activity. You
                                hereby grant USA Cyber Services a non-exclusive
                                license to use, reproduce, edit and authorize
                                others to use, reproduce and edit any of your
                                Comments in any and all forms, formats, or
                                media.
                            </ListItem>
                        </List>
                    </Paper>

                    <Paper sx={{ p: 2 }}>
                        <Typography variant="h2" fontWeight={'bold'}>
                            Hyperlinking to our Content
                        </Typography>
                        <Divider sx={{ marginBottom: '1rem' }} light />

                        <Typography variant="h4">
                            The following organizations may link to our Website
                            without prior written approval:
                        </Typography>
                        <List sx={{ listStyleType: 'disc', pl: 4 }}>
                            <ListItem sx={{ display: 'list-item' }}>
                                Government agencies;
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                Search engines;
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                News organizations;
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                Online directory distributors may link to our
                                Website in the same manner as they hyperlink to
                                the Websites of other listed businesses;
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                and System-wide Accredited Businesses except
                                soliciting non-profit organizations, charity
                                shopping malls, and charity fundraising groups
                                which may not hyperlink to our Web site.
                            </ListItem>
                        </List>

                        <Box sx={{ ml: '2vw' }}>
                            <Typography variant="h4">
                                These organizations may link to our home page,
                                to publications, or to other Website information
                                so long as the link:
                            </Typography>
                            <List sx={{ listStyleType: 'lower-alpha', pl: 4 }}>
                                <ListItem sx={{ display: 'list-item' }}>
                                    is not in any way deceptive;
                                </ListItem>
                                <ListItem sx={{ display: 'list-item' }}>
                                    does not falsely imply sponsorship,
                                    endorsement, or approval of the linking
                                    party and its products and/or services; and
                                </ListItem>
                                <ListItem sx={{ display: 'list-item' }}>
                                    fits within the context of the linking
                                    partyapos;s site.
                                </ListItem>
                            </List>
                            <Typography variant="h4">
                                We will approve link requests from these
                                organizations if we decide that:
                            </Typography>
                            <List sx={{ listStyleType: 'lower-alpha', pl: 4 }}>
                                <ListItem sx={{ display: 'list-item' }}>
                                    the link would not make us look unfavourably
                                    to ourselves or to our accredited
                                    businesses;
                                </ListItem>
                                <ListItem sx={{ display: 'list-item' }}>
                                    the organization does not have any negative
                                    records with us;
                                </ListItem>
                                <ListItem sx={{ display: 'list-item' }}>
                                    the benefit to us from the visibility of the
                                    hyperlink compensates the absence of USA
                                    Cyber Services; and
                                </ListItem>
                                <ListItem sx={{ display: 'list-item' }}>
                                    the link is in the context of general
                                    resource information.
                                </ListItem>
                            </List>
                            <Typography variant="h4">
                                These organizations may link to our home page so
                                long as the link:
                            </Typography>
                            <List sx={{ listStyleType: 'lower-alpha', pl: 4 }}>
                                <ListItem sx={{ display: 'list-item' }}>
                                    is not in any way deceptive;
                                </ListItem>
                                <ListItem sx={{ display: 'list-item' }}>
                                    does not falsely imply sponsorship,
                                    endorsement, or approval of the linking
                                    party and its products or services; and
                                </ListItem>
                                <ListItem sx={{ display: 'list-item' }}>
                                    fits within the context of the linking
                                    partyapos;s site.
                                </ListItem>
                            </List>
                        </Box>

                        <Paper variant="outlined" sx={{ p: 2 }}>
                            <Typography variant="h4" fontWeight={'bold'}>
                                If you are one of the organizations listed above
                                and are interested in linking to our website,
                                you must inform us by sending an e-mail to or
                                contacting USA Cyber Services. Please include
                                your name, your organization name, contact
                                information as well as the URL of your site, a
                                list of any URLs from which you intend to link
                                to our Website, and a list of the URLs on our
                                site to which you would like to link. Wait 2-3
                                weeks for a response.
                            </Typography>
                        </Paper>

                        <Typography sx={{ mt: '2vh' }} variant="h4">
                            Approved organizations may hyperlink to our Website
                            as follows:
                        </Typography>
                        <List sx={{ listStyleType: 'disc', pl: 4 }}>
                            <ListItem sx={{ display: 'list-item' }}>
                                By use of our corporate name; or By use of the
                                uniform resource locator being linked to;
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                or Using any other description of our Website
                                being linked to that makes sense within the
                                context and format of content on the linking
                                partyapos;s site.
                            </ListItem>
                        </List>
                        <Paper variant="outlined" sx={{ p: 2 }}>
                            <Typography variant="h4" fontWeight={'bold'}>
                                No use of USA Cyber Servicesapos;s logo or other
                                artwork will be allowed for linking absent a
                                trademark license agreement.
                            </Typography>
                        </Paper>
                    </Paper>

                    <Paper sx={{ p: 2 }}>
                        <Typography variant="h2" fontWeight={'bold'}>
                            Content Liability:
                        </Typography>
                        <Divider sx={{ marginBottom: '1rem' }} light />

                        <Typography variant="h4">
                            We shall not be held responsible for any content
                            that appears on your Website. You agree to protect
                            and defend us against all claims that are raised on
                            your Website. No link(s) should appear on any
                            Website that may be interpreted as libellous,
                            obscene, or criminal, or which infringes, otherwise
                            violates, or advocates the infringement or other
                            violation of, any third party rights.
                        </Typography>
                    </Paper>

                    <Paper sx={{ p: 2 }}>
                        <Typography variant="h2" fontWeight={'bold'}>
                            Reservation of Rights:
                        </Typography>
                        <Divider sx={{ marginBottom: '1rem' }} light />

                        <Typography variant="h4">
                            We reserve the right to request that you remove all
                            links or any particular link to our Website. You
                            approve to immediately remove all links to our
                            Website upon request. We also reserve the right to
                            amend these terms and conditions and its linking
                            policy at any time. By continuously linking to our
                            Website, you agree to be bound to and follow these
                            linking terms and conditions.
                        </Typography>
                    </Paper>

                    <Paper sx={{ p: 2 }}>
                        <Typography variant="h2" fontWeight={'bold'}>
                            Removal of links from our website:
                        </Typography>
                        <Divider sx={{ marginBottom: '1rem' }} light />

                        <Typography variant="h4">
                            If you find any link on our Website that is
                            offensive for any reason, you are free to contact
                            and inform us at any moment. We will consider
                            requests to remove links, but we are not obligated
                            to or so or to respond to you directly. We do not
                            ensure that the information on this website is
                            correct. We do not warrant its completeness or
                            accuracy, nor do we promise to ensure that the
                            website remains available or that the material on
                            the website is kept up to date.
                        </Typography>
                    </Paper>

                    <br />
                    <Paper variant="outlined" sx={{ p: 2 }}>
                        <Typography variant="h2" fontWeight={'bold'}>
                            Disclaimer:
                        </Typography>
                        <Divider sx={{ marginBottom: '1rem' }} light />

                        <Typography variant="h4">
                            To the maximum extent permitted by applicable law,
                            we exclude all representations, warranties, and
                            conditions relating to our website and the use of
                            this website. Nothing in this disclaimer will:
                        </Typography>
                        <List sx={{ listStyleType: 'disc', pl: 4 }}>
                            <ListItem sx={{ display: 'list-item' }}>
                                limit or exclude our or your liability for death
                                or personal injury;
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                limit or exclude our or your liability for fraud
                                or fraudulent misrepresentation;
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                limit any of our or your liabilities in any way
                                that is not permitted under applicable law; or
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                exclude any of our or your liabilities that may
                                not be excluded under applicable law.
                            </ListItem>
                        </List>
                        <br />
                        <Typography variant="h4">
                            The limitations and prohibitions of liability set in
                            this Section and elsewhere in this disclaimer: As
                            long as the website and the information and services
                            on the website are provided free of charge, we will
                            not be liable for any loss or damage of any nature.
                        </Typography>
                        <List sx={{ listStyleType: 'lower-alpha', pl: 4 }}>
                            <ListItem sx={{ display: 'list-item' }}>
                                are subject to the preceding paragraph; and
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                govern all liabilities arising under the
                                disclaimer, including liabilities arising in
                                contract, in tort, and for breach of statutory
                                duty.
                            </ListItem>
                        </List>
                        <br />
                        <Typography variant="h4">
                            As long as the website and the information and
                            services on the website are provided free of charge,
                            we will not be liable for any loss or damage of any
                            nature.
                        </Typography>
                    </Paper>
                </Box>
            </Box>
        </Box>
    );
};

export default TermsOfService;
