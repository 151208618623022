import { useState } from 'react';
import {
    Box,
    Button,
    IconButton,
    Typography,
    useTheme,
    Grid,
} from '@mui/material';
import { getColors } from '@src/global/context/theme';

import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import SouthEastOutlinedIcon from '@mui/icons-material/SouthEastOutlined';

import Header from '@src/global/components/Header';
import StatBox from '@src/global/components/StatBox';
import ProgressCircle from '@src/global/components/ProgressCircle';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

const Dashboard = (): JSX.Element => {
    const theme = useTheme();
    const colors = getColors(theme.palette.mode);

    const { userInfo } = useSelector((state: any) => state.auth, shallowEqual);

    // const [recentActions, setRecentActions] = useState([]);

    // const reloadRecentActions = () => {
    //   axios.get(process.env.REACT_APP_API_URI as string + "/api/actions/recent").then((res: any) => {
    //     if (res && res.status == 200) {
    //       if (res.data && res.data.success) {
    //         setRecentActions(res.data.data);
    //         return;
    //       }
    //       if (res.data && res.data.success == false) {
    //         toast.error(res.data.message);
    //         return;
    //       }
    //       return;
    //     }
    //     toast.error('Unhandled response. Please consult your local developer.');
    //   })
    // };

    // const valueFormatter = (number: number) => `$ ${Intl.NumberFormat("us").format(number).toString()}`;

    return (
        <Box m="20px">
            {/* HEADER */}
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Header
                    title="DASHBOARD"
                    subtitle={
                        'Welcome to your dashboard, ' +
                        (userInfo
                            ? userInfo.roblox
                                ? userInfo.roblox.username
                                    ? userInfo.roblox.username
                                    : 'Default'
                                : 'Default'
                            : 'Default')
                    }
                />{' '}
                {/* (👷 COMING SOON 🚧 ) */}
                <Box>
                    <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: '14px',
                            fontWeight: 'bold',
                            padding: '10px 20px',
                        }}
                        disabled={true}
                    >
                        {/* TODO: Generate and send reports to the client. */}
                        <DownloadOutlinedIcon sx={{ mr: '10px' }} />
                        Download Reports
                    </Button>
                </Box>
            </Box>

            {/* GRID & CHARTS */}
            <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="140px"
                gap="20px"
            >
                <Box
                    sx={{
                        gridColumn: 'span 7',
                        gridRow: 'span 2',
                        backgroundColor: colors.primary[400],
                        overflow: 'auto',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: `4px solid ${colors.primary[500]}`,
                            colors: colors.grey[100],
                            p: '15px',
                        }}
                    >
                        <Grid container alignItems="center">
                            <Grid item xs={4}>
                                <Typography
                                    color={colors.grey[100]}
                                    variant="h5"
                                    fontWeight="600"
                                >
                                    Recent Uploads
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography
                                    color={colors.grey[100]}
                                    variant="h5"
                                    fontWeight="600"
                                >
                                    Date
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography
                                    color={colors.grey[100]}
                                    variant="h5"
                                    fontWeight="600"
                                >
                                    File
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    {(
                        [
                            {
                                id: 1,
                                actor: 'Jon Snow',
                                date: new Date(Date.now()).toLocaleString(
                                    'en-US',
                                    { timeZone: 'America/Chicago' },
                                ),
                                data: { file: 'file1.pdf' },
                            },
                        ] as any
                    ).map((upload: any, i: any) => (
                        <Box
                            key={`${upload.id}-${i}`}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            borderBottom={`4px solid ${colors.primary[500]}`}
                            p="15px"
                        >
                            <Grid container alignItems="center">
                                <Grid item xs={4}>
                                    <Box>
                                        <Typography
                                            color={colors.greenAccent[500]}
                                            variant="h5"
                                            fontWeight="600"
                                        >
                                            {upload.actor}
                                        </Typography>
                                        <Typography color={colors.grey[100]}>
                                            {upload.data.file}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box color={colors.grey[100]}>
                                        {upload.date}
                                    </Box>
                                </Grid>
                                <Grid item xs={4} md={2}>
                                    <Box
                                        sx={{
                                            backgroundColor:
                                                colors.greenAccent[500],
                                            p: '5px 10px',
                                            borderRadius: '4px',
                                        }}
                                    >
                                        {upload.data.file}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    ))}
                </Box>

                <Box
                    sx={{
                        gridColumn: 'span 5',
                        gridRow: 'span 2',
                        backgroundColor: colors.primary[400],
                        overflow: 'auto',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: `4px solid ${colors.primary[500]}`,
                            colors: colors.grey[100],
                            p: '15px',
                        }}
                    >
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item xs={4}>
                                <Typography
                                    color={colors.grey[100]}
                                    variant="h5"
                                    fontWeight="600"
                                >
                                    Recent Actions
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography
                                    color={colors.grey[100]}
                                    variant="h5"
                                    fontWeight="600"
                                >
                                    Date
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography
                                    color={colors.grey[100]}
                                    variant="h5"
                                    fontWeight="600"
                                >
                                    File
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    {(
                        [
                            {
                                id: 1,
                                actor: 'Jon Snow',
                                date: new Date(Date.now()).toLocaleString(
                                    'en-US',
                                    { timeZone: 'America/Chicago' },
                                ),
                                action: 'Deleted a file',
                                data: { file: 'file1.pdf' },
                            },
                            {
                                id: 2,
                                actor: 'Jon Snow',
                                date: new Date(Date.now()).toLocaleString(
                                    'en-US',
                                    { timeZone: 'America/Chicago' },
                                ),
                                action: 'Uploaded a file',
                                data: { file: 'file1.pdf' },
                            },
                        ] as any
                    ).map((action: any, i: any) => (
                        <Box
                            key={`${action.id}-${i}`}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            borderBottom={`4px solid ${colors.primary[500]}`}
                            p="15px"
                        >
                            <Grid container alignItems="center">
                                <Grid item xs={4}>
                                    <Box>
                                        <Typography
                                            color={
                                                action.action ===
                                                'Uploaded a file'
                                                    ? colors.greenAccent[500]
                                                    : colors.redAccent[400]
                                            }
                                            variant="h5"
                                            fontWeight="600"
                                        >
                                            {action.actor}
                                        </Typography>
                                        <Typography color={colors.grey[100]}>
                                            {action.action}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box color={colors.grey[100]}>
                                        {action.date}
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box
                                        sx={{
                                            backgroundColor:
                                                action.action ===
                                                'Uploaded a file'
                                                    ? colors.greenAccent[500]
                                                    : colors.redAccent[400],
                                            p: '5px 10px',
                                            borderRadius: '4px',
                                        }}
                                    >
                                        {action.data.file}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    ))}
                </Box>
            </Box>

            <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="140px"
                gap="20px"
                marginTop="20px"
            >
                <Box
                    sx={{
                        gridColumn: 'span 5',
                        gridRow: 'span 2',
                        backgroundColor: colors.primary[400],
                        overflow: 'auto',

                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Bar
                        options={{
                            responsive: true,
                            plugins: {
                                legend: {
                                    position: 'top' as const,
                                    labels: {
                                        color: colors.grey[100],
                                    },
                                },
                                title: {
                                    display: true,
                                    text: 'Chart.js Bar Chart',
                                    color: colors.grey[100],
                                },
                            },
                            scales: {
                                x: {
                                    ticks: {
                                        color: colors.grey[100],
                                    },
                                },
                                y: {
                                    ticks: {
                                        color: colors.grey[100],
                                    },
                                },
                            },
                        }}
                        data={{
                            labels,
                            datasets: [
                                {
                                    label: 'Dataset 1',
                                    data: labels.map(() =>
                                        Math.floor(Math.random() * 1000),
                                    ),
                                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                },
                                {
                                    label: 'Dataset 2',
                                    data: labels.map(() =>
                                        Math.floor(Math.random() * 1000),
                                    ),
                                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                },
                            ],
                        }}
                    />
                </Box>

                <Box
                    sx={{
                        gridColumn: 'span 2',
                        gridRow: 'span 2',
                        overflow: 'auto',

                        display: 'grid',
                        direction: 'column',
                        gridTemplateColumns: 'repeat(1, 1fr)',
                        gridAutoRows: '140px',
                        gap: '20px',
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: colors.primary[400],
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <StatBox
                            title="1 issue(s)"
                            subtitle="Average Issue Count"
                            noProgressCircle
                            icon={
                                <ErrorOutlinedIcon
                                    sx={{
                                        color: colors.redAccent[400],
                                        fontSize: '26px',
                                    }}
                                />
                            }
                        />
                    </Box>

                    <Box
                        sx={{
                            backgroundColor: colors.primary[400],
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <StatBox
                            title="1 issue(s)"
                            subtitle="Average Dropped Off"
                            noProgressCircle
                            // progress="0.50"
                            // change="-1 issue(s)"
                            icon={
                                <SouthEastOutlinedIcon
                                    sx={{
                                        color: colors.redAccent[200],
                                        fontSize: '26px',
                                    }}
                                />
                            }
                        />
                    </Box>
                </Box>

                {/* <Box
          sx={{
            gridColumn: "span 4",
            gridRow: "span 2",
            backgroundColor: colors.primary[400],
            overflow: "auto",
          }}
        > */}
                {/* <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: `4px solid ${colors.primary[500]}`,
              colors: colors.grey[100],
              p: "15px",
            }}
          >
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={4}>
                <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
                  Recent Actions
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
                  Date
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
                  File
                </Typography>
            </Grid>
          </Box> */}
                {/* {([
            { id: 1, actor: "Jon Snow", date: (new Date(Date.now())).toLocaleString('en-US', { timeZone: 'America/Chicago' }), action: "Deleted a file", data: { file: "file1.pdf", }},
            { id: 2, actor: "Jon Snow", date: (new Date(Date.now())).toLocaleString('en-US', { timeZone: 'America/Chicago' }), action: "Uploaded a file", data: { file: "file1.pdf", }}
          ] as any).map((action: any, i: any) => (
            <Box
              key={`${action.id}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Grid
                container
                alignItems="center"
              >
                <Grid item xs={4}>
                  <Box>
                    <Typography
                      color={ action.action == "Uploaded a file" ? colors.greenAccent[500] : colors.redAccent[400]}
                      variant="h5"
                      fontWeight="600"
                    >
                      {action.actor}
                    </Typography>
                    <Typography color={colors.grey[100]}>
                      {action.action}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box color={colors.grey[100]}>{action.date}</Box>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    sx={{
                      backgroundColor: action.action == "Uploaded a file" ? colors.greenAccent[500] : colors.redAccent[400],
                      p: "5px 10px",
                      borderRadius: "4px",
                    }}
                  >
                    {action.data.file}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))} */}

                {/* </Box> */}
            </Box>
        </Box>
    );
};

export default Dashboard;
